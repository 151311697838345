
import "../../node_modules/leaflet/dist/leaflet.css";
import MyMap from "../Componets/MyMap";
import React from 'react';
import SidebarCustom from "../Componets/SideBarCustom";
import axios from "axios";
import XMLParser from 'react-xml-parser';
import { useState,useEffect } from "react";
import TopBar from "../Componets/TopBar";
import { COffcanvas,COffcanvasHeader,COffcanvasTitle,CCloseButton,COffcanvasBody } from '@coreui/react';
import SearchPOI from "../Componets/SearchPOI";
import SpesaPubbicaModal from "../Componets/SpesaPubblicaModal";
//import overpass from "query-overpass";
import BasicTable from "../Componets/BasicTable";
import InpsModal from "../Componets/InpsModal";
import SuoloModal from "../Componets/SuoloModal";

//import HoverElement from "../Componets/HoverElement"


// Viene ricavata la lista di Navigazione della TOP BAR
var navigation;
// Viene ricavata la lista di filtri comprensivi di icona e status (verrà utilizzato dalla mappa)
var myNodeList;






var datiMain = [];
//var visibilityState="hidden";




export default function MainPageTest(props) {
  const [visible, setVisible] = useState(false);
  var [position, setPosition] = useState([38.12006767554346, 13.35830013977314]);
  const [gpsState, setGpsState] =useState(true);
  var [flyTo, setFlyTo] = useState([]);
  const [comune,setComune] = useState("");
  const [procom,setProcom] = useState("");
  const [sigla,setSigla] = useState("");
  const [comunepolygon,setComunePolygon]=useState([]);
  const [bandapolygon,setBandaPolygon]=useState();
  const [markerpolygon,setMarkerPolygon]=useState([0,0]);

  const [spVisible,setSpVisible]=useState(false);
  const [inpsVisible,setInpsVisible]=useState(false);
  const [suoloVisible,setSuoloVisible]=useState(false);
  const [spData,setSpData]=useState([]);
  const [inpsData,setInpsData]=useState([]);
  const [suoloData,setSuoloData]=useState(Object);
  const [spRendicontoData,setSpRendicontoData]=useState([]);
  const [spDenominazione,setSpDenominazione]=useState("");
  const [spEsercizio,setSpEsercizio]=useState("");
  


  const [siglaPrecedente,setSiglaPrecedente]=useState();
  const [procomPrecedente,setProcomPrecedente]=useState();
 
  var [search, setSearch]=useState("");
    const { keycloak } = props;
    //console.log("Token Keyclock"+keycloak.token);
    var [visibilityState, setVisibleState] = useState("hidden");
  
   // var visibilityState="hidden";
   
   //alert("Adesso siamo in main ed abbiamo"+procom+comune)  

    const settaCaricamento = (caricamento) => {
        setVisibleState(caricamento);
    };


    const funzioneSincronizzazione = (filtro) => {
   //   console.log("Sincronizzazione");
    //  console.log(filtro);
        datiMain = filtro;
    };
    const funzioneRecupero = () => {
        if (datiMain) {
            return datiMain;
        }
    };
    const getSpVisible = () => {
    
          return spVisible;
    
     };

    const getDataSpesaPubblica = (codicecomune) => {
      if (codicecomune) {

           // alert("Codice comune da getdaa"+codicecomune);
          // axios(`https://paladin.expleoitalia.it/service/api/spesa_pubblica/previsione/${codicecomune}`, {
          //     method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
          // }, { timeout: 2000 }).then

          //     (response => {
             
          //          setSpData(response.data.missioni);

          //     }
          //     );
              axios(`https://paladin.expleoitalia.it/service/api/spesa_pubblica/rendiconto/${codicecomune}`, {
              method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
          }, { timeout: 2000 }).then

              (response => {
              //    console.log("Tracciamento da spesa pubblica Modal");
              //    console.log(response.data.missioni);
                   setSpRendicontoData(response.data.missioni);
                   setSpDenominazione(response.data.denominazione_soggetto);
                   setSpEsercizio(response.data.esercizio_finanziario);
              }
              ); 
      }
  }
  const getDataSuolo = (codicecomune) => {
    if (codicecomune) {

         // alert("Codice comune da getdaa"+codicecomune);
        // axios(`https://paladin.expleoitalia.it/service/api/spesa_pubblica/previsione/${codicecomune}`, {
        //     method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
        // }, { timeout: 2000 }).then

        //     (response => {
           
        //          setSpData(response.data.missioni);

        //     }
        //     );
            axios(`https://paladin.expleoitalia.it/service/api/sara/ispra/suolo/${codicecomune}`, {
            method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
        }, { timeout: 2000 }).then

            (response => {
              setSuoloData(response.data);
              console.log("Abbiamo settato suolo DATA");
              console.log(response.data);
            //    console.log("Tracciamento da spesa pubblica Modal");
            //    console.log(response.data.missioni);
               //  setSpRendicontoData(response.data.missioni);
               //  setSpDenominazione(response.data.denominazione_soggetto);
               //  setSpEsercizio(response.data.esercizio_finanziario);
            }
            ); 
    }
}

  const getDataInps = (siglacomune) => {
    if (siglacomune) {

          // alert("Eccoci in data inps"+siglacomune);
           axios(`https://paladin.expleoitalia.it/service/api/inps/provincia/${siglacomune}`, {
            method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
        }, { timeout: 2000 }).then

            (response => {
                 console.log("Tracciamento da inps");
                 console.log(response.data)
                 setInpsData(response);

            }
            );
           
    }
}


    const getSearch =() => 
    {
    // alert("Ho fatto una get search sul main ed ho avuto"+search);
     return(search);
 
    };

    const getGpsState =() => 
    {
     return(gpsState);
 
    };

    const resetSearch=()=>
    {
     // alert("Ho fatto una reset search");
     setSearch(""); 
    }

   var filtrochanged=false;
   useEffect(() => 
   {
    if(siglaPrecedente!=sigla)
    {
     
     getDataInps(sigla);
    // getDataSpesaPubblica(procom); 
     setSiglaPrecedente(sigla);
    }
    if(procomPrecedente!=procom)
    {
     
     //getDataInps(sigla);
     getDataSuolo(procom); 
     getDataSpesaPubblica(procom); 
     setProcomPrecedente(procom);
    
    }
   
   });

    

    if(spRendicontoData.length==0) { getDataSpesaPubblica(procom) }; 
    if(suoloData.length==0) {getDataSuolo(procom)}
    if(inpsData.length==0) { getDataInps(sigla)};
    
    
    return (
        <>



    
<>
<div >
      <div data-dial-init className="fixed bottom-1/2 left-1/2  group z-[10000]">
       <div role="status">

         <button disabled type="button" style={{visibility: visibilityState}} class=" py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
         <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
         <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
         </svg> 
      
              Loading...
         </button>
      </div>
     </div>


      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="flex">
        ```
      */}
      
       
      
         
      <div data-dial-init className="fixed bottom-1/2 left-1/2  group z-[10000]">
       <div role="status">

         <button disabled type="button" style={{visibility: visibilityState}} class=" py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
         <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M100 50.5908C100 78.205   1 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
         <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
         </svg>
              Loading...
         </button>
      </div>
     </div>
    
      {/*  <header className="bg-gray-800 h-0.5 shadow "> 
          <div className="mx-auto  py-6 px-4 sm:px-6 lg:px-8">



          
      
 
          
             
           
          
           </div>
       </header>*/}
        <div className="flex flex-col h-screen ">
        
           <div className="fixed top-0 left-1  w-full group z-[1000]">
         
              <TopBar token={props.keycloak.token} comune={comune}  setComune={setComune}  setProcom={setProcom} setSigla={setSigla} setComunePolygon={setComunePolygon} setBandaPolygon={setBandaPolygon} setMarkerPolygon={setMarkerPolygon} keycloak={keycloak} gpsState={gpsState} setGpsState={setGpsState} setComune={setComune} setVisible={setVisible} setSearch={setSearch} position={position} />
           
            </div>
        
           <div className=" h-full  py-0 sm:px-0 ">
           <div className="flex h-full" >
             <div className=" h-full max-lg:w-full w-full"> 
                
                    <SpesaPubbicaModal comune={comune} setSpVisible={setSpVisible} spVisible={spVisible} getSpVisible={getSpVisible} spDenominazione={spDenominazione} spEsercizio={spEsercizio} spRendicontoData={spRendicontoData} />
                 
                
                   <InpsModal     comune={comune}    setInpsVisible={setInpsVisible} inpsVisible={inpsVisible}  inpsData={inpsData} />
                   <SuoloModal     comune={comune}    setSuoloVisible={setSuoloVisible} suoloVisible={suoloVisible}  suoloData={suoloData} />
                 
                   
                   <MyMap             className="" flyTo={flyTo}  customBandaMarker={markerpolygon} comunepolygon={comunepolygon} bandapolygon={bandapolygon} setComunePolygon={setComunePolygon}  setSuoloData={setSuoloData} setBandaPolygon={setBandaPolygon} setGpsState={setGpsState}  position={position} procom={procom} comune={comune} getGpsState={getGpsState} setPosition={setPosition} setSpVisible={setSpVisible} setVisible={setVisible} setInpsVisible={setInpsVisible} setSuoloVisible={setSuoloVisible} syncToParent={funzioneSincronizzazione} filtrochanged={filtrochanged} search={search} resetSearch={resetSearch} getSearch={getSearch} settaCaricamento={settaCaricamento} keycloak={keycloak}  />
                
              </div> 
            </div>
            {/* /End replace */}
          </div>


        </div>
      </div>
    </>


         
        
  
    <COffcanvas backdrop={false} placement="left top-20 "   scroll visible={visible} >
   
         {/* <COffcanvas placement="left top-0" visible={visible} onHide={() => setVisible(false)}> */}
          <COffcanvasHeader>
            <COffcanvasTitle > </COffcanvasTitle>
            <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
          </COffcanvasHeader>
          <COffcanvasBody>
          <SidebarCustom  setFlyTo={setFlyTo} DatiMain={funzioneRecupero} setGpsState={setGpsState} setPosition={setPosition}settaCaricamento={settaCaricamento} />
          </COffcanvasBody>
        </COffcanvas>  
      </>
    )
}
