import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { middleWare } from "./MiddleWare";
import { loadingState } from "./LoadingState";
import { errorState } from "./ErrorState";
import { selectPosition } from "./SelectPositionState";
import { meteoFetch } from "./MeteoState";

import {registrationFormSlice} from "./RegistrationFormSlice";

const rootReducer = combineReducers({
  position: selectPosition.reducer,
  meteo: meteoFetch.reducer,
 
  UI: combineReducers({
    loading: loadingState.reducer,
    error: errorState.reducer,
  }),
  registration: registrationFormSlice.reducer,
});

export const store = legacy_createStore(
  rootReducer,
  applyMiddleware(middleWare, thunkMiddleware)
);
