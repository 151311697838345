import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "flowbite-react";
import save from "../IMG/save.svg";
import saved from "../IMG/saved.svg";
import starSolid from "../IMG/starSolid.svg";
import starRegular from "../IMG/starRegular.svg";
import trashRegular from "../IMG/trashRegular.svg"
import { useParams } from "react-router-dom";

function Events() {
  const { token } = useParams();
  const [events, setEvents] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState();
  const [calendarIsOpen1, setCalendarIsOpen1] = useState(false);
  const [calendarIsOpen2, setCalendarIsOpen2] = useState(false);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [initialDate, setiInitialDate] = useState("");
  const [finalDate, setiFinalDate] = useState("");
  const [showModal, setShow] = useState(false);
  const [modalEvent, setModalEvent] = useState({});
  const [favoriteEvents, setFavoriteEvents] = useState([]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("it-IT", options).format(
      date
    );
    return formattedDate;
  }

  const getEvents = () => {

    axios(`https://paladin.expleoitalia.it/service/api/strapi/api/eventos/?populate=*`,   {
      method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + token },
    }, { timeout: 2000 }).then((response) => {
   
        const newsData = response.data.data.map((item) => {
          return {
            ...item,
            favorite: false,
          };
        });
        setEvents(newsData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEvents();
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 639);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = (event) => {
    event.currentTarget.style.transform = "scale(1.05)";
    event.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.3)";
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.transform = "scale(1)";
    event.currentTarget.style.boxShadow = "none";
  };

  const handleCalendar1 = () => {
    if (!calendarIsOpen1) {
      setCalendarIsOpen1(!calendarIsOpen1);
    } else if (calendarIsOpen1 && !initialDate) {
      setCalendarIsOpen1(!calendarIsOpen1);
    } else {
      return;
    }
  };

  const handleCalendar2 = () => {
    if (!calendarIsOpen2) {
      setCalendarIsOpen2(!calendarIsOpen2);
    } else if (calendarIsOpen2 && !finalDate) {
      setCalendarIsOpen2(!calendarIsOpen2);
    } else {
      return;
    }
  };

  const handleModal = (id) => {
    setShow(!showModal);
    const event = events.find((item) => item.id === id);
    if (event) {
      setModalEvent(event);
    }
  };

  const handleToggleFavorites = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
  };

  const handleFav = (id) => {
    const updatedEvents = events.map((item) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          favorite: !item.favorite,
        };
        // Aggiorna l'elemento specifico nell'array favoriteEvents
        const updatedFavoriteEvents = [...favoriteEvents]; // Crea una copia dell'array favoriteEvents
        const index = updatedFavoriteEvents.findIndex(
          (eventsItem) => eventsItem.id === id
        );

        if (index !== -1) {
          updatedFavoriteEvents.splice(index, 1); // Rimuovi l'elemento se è già presente
        } else {
          updatedFavoriteEvents.push(item); // Aggiungi l'elemento se non è presente
        }

        setFavoriteEvents(updatedFavoriteEvents);

        return updatedItem;
      }
      return item;
    });
    setEvents(updatedEvents);

    console.log(updatedEvents);
  };

  const getFavIcon = (item) => {
    if (showFavoritesOnly) { 
     
      return (
        <img
          src={trashRegular}
          alt="rimuovi dai preferiti"
          style={{ width: "15px", height: "20px" }}
          onClick={() => {
            handleFav(item.id);
            if (showModal) {
              handleModal();
            }
          }}
        />
      
      ); 
    } else {
      return item.favorite ? (
        <img
          src={saved}
          alt="salvato"
          style={{ width: "15px", height: "20px" }}
        />
      ) : (
        <img
          src={save}
          alt="salva"
          style={{ width: "15px", height: "20px" }}
        />
      );
    }
  };

  const filteredEvents = showFavoritesOnly ? favoriteEvents : events;

  const stampa = () => {
    console.log(events);
    console.log(window.innerWidth);
    console.log(initialDate);
    console.log(modalEvent);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full text-center bg-white pt-4">
        <h1 className="text-5xl">Eventi</h1>
        <p className="text-sm px-12 sm:text-xl">
          Segnati in agenda i nostri eventi. Registrati e{" "}
          <b>partecipa agli eventi </b> che più ti interessano per essere sempre
          informato a 360° sui tuoi diritti di Consumatore!
        </p>
      </div>

      <div className="w-full sm:w-auto flex flex-col">
        <div className="flex flex-col lg:flex-row gap-2 p-2 justify-between w-full">
          {calendarIsOpen1 ? (
            <input
              className="w-100 h-10 pldInput"
              type="date"
              value={initialDate}
              onChange={(e) => setiInitialDate(e.target.value)}
              onBlur={handleCalendar1}
            />
          ) : (
            <input
              className="w-100 h-10 pldInput rounded-md"
              placeholder="Dal"
              onClick={handleCalendar1}
              type="textarea"
            />
          )}

          {calendarIsOpen2 ? (
            <input
              className="w-100 h-10 pldInput"
              type="date"
              value={finalDate}
              onChange={(e) => setiFinalDate(e.target.value)}
              onBlur={handleCalendar2}
            />
          ) : (
            <input
              className="w-100 h-10 pldInput rounded-md"
              placeholder="Al"
              onClick={handleCalendar2}
              type="textarea"
            />
          )}

          <div className="flex flex-row w-full">
            <button
              className="bg-white rounded-lg w-16 h-10"
              onClick={handleToggleFavorites}
            >
              {showFavoritesOnly ? (
                <img
                  src={starSolid}
                  alt="Mostra tutti"
                  style={{ width: "100px", height: "20px" }}
                />
              ) : (
                <img
                  src={starRegular}
                  alt="Mostra i preferiti"
                  style={{ width: "100px", height: "20px" }}
                />
              )}
            </button>
            <button className="pldButton w-full" onClick={stampa}>
              Cerca
            </button>
          </div>
        </div>
        {filteredEvents.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 pt-8 rounded-lg">
            {filteredEvents.map((item) => (
              <>
                {modalEvent && modalEvent.attributes && (
                  <Modal show={showModal} onClose={() => handleModal()}>
                    <Modal.Header>
                      <div className="flex justify-center flex-col text-center">
                        <img
                          src={
                            "https://paladin.expleoitalia.it/api/strapi" +
                            modalEvent.attributes.Immagine.data.attributes.url.replace('/uploads', '')
                          }
                          alt={
                            modalEvent.attributes.Immagine.data.attributes
                              .caption
                          }
                        />
                        {modalEvent.attributes.Titolo_evento}
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="space-y-6">
                        <div className="flex justify-between">
                          <div className="text-xs pldBold flex items-center justify-start py-2 px-2">
                            {formatDate(modalEvent.attributes.Data_evento)}
                          </div>
                          <div className="pldBold text-xs flex items-center text-end px-2">
                            {modalEvent.attributes.Luogo_di_svolgimento}
                          </div>
                        </div>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                          {modalEvent.attributes.Testo_evento}
                        </p>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div>
                        <button
                          className="flex justify-center pr-2 pb-2"
                          onClick={() => handleFav(modalEvent.id)}
                        >
                         {getFavIcon(modalEvent)}
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                )}
                <div
                  key={item.id}
                  className="pldCard mx-2 mb-4 last:mb-0 pt-4 rounded-lg relative w-[95%] sm:w-60 h-auto sm:h-96"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ transition: "transform 0.2s, box-shadow 0.2s" }}
                >
                  <div onClick={() => handleModal(item.id)}>
                    <div className="flex justify-center">
                      <img
                        src={
                          "https://paladin.expleoitalia.it/strapi" +
                          item.attributes.Immagine.data.attributes.url.replace('/uploads', '')
                        }
                        alt={item.attributes.Immagine.data.attributes.caption}
                      />
                    </div>
                    <div className="flex justify-between">
                      <div className="text-xs pldBold flex items-center justify-start py-2 px-2">
                        {formatDate(item.attributes.Data_evento)}
                      </div>
                      <div className="pldBold text-xs flex items-center text-end px-2">
                        {item.attributes.Luogo_di_svolgimento}
                      </div>
                    </div>
                    <div className="flex flex-row justify-center items-center w-full px-2 mx-2 h-16">
                      <h2 className=" text-start mr-2 pldText text-auto sm:text-2xl">
                        {item.attributes.Titolo_evento}
                      </h2>{" "}
                    </div>
                    {item.attributes.Titolo_evento.length <= 70 &&
                    !isSmallScreen ? (
                      <div className="mx-2" style={{ overflow: "hidden" }}>
                        <p className="articolo text-sm">
                          {item.attributes.Testo_evento}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="bottom-0 w-full flex justify-end"
                    
                  >
                    <button className="pr-2 pb-2" onClick={() => handleFav(item.id)}>
                      {getFavIcon(item)}
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          <p className="text-center">Nessun evento trovato!</p>
        )}
      </div>
    </div>
  );
}

export default Events;
