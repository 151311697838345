import { Circle } from "react-leaflet";
import { useState, useEffect } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polygon,
  ZoomControl,
  GeoJSON,
} from "react-leaflet";
import "../../node_modules/leaflet/dist/leaflet.css";
import L from "leaflet";
import React from "react";
import { LayerGroup, LayersControl,useMapEvents } from "react-leaflet";

import axios from "axios";
import SpeedDialXml from "./SpeedDialXml";

import logo from "../IMG/logo.svg";



import OpereModal from "./OpereModal";
import SchoolModal from "./SchoolModal";
import { Await } from "react-router-dom";






const codicecomune=72011;
var mapZoomGeneral = 18;
var proportionalGeneral = 21;
var visibleGeneral = "visible";
var filtroside = '';
var iconside = '';
var colorside = 'green';

var stringacss = 'rounded-full p-1 flex justify-center';


const multiPolygon = 
  
  [ [38.1348233216002, 13.3564222002135],
    [38.1048162000001, 13.3562135000001],
    [38.1048160000002,13.34641333330035]
]
  




const MyMap = (props) => {


  const { keycloak } = props;
  
  console.log("Ecco il token"+keycloak.token);

  let map;
  props.syncToParent();
//  const [primocaricamento, setCaricamento] = useState(false);
//  var [search,setSearch]=useState(props.search);

 // var [position, setPosition] = useState([38.12006767554346, 13.35830013977314]);
  var position=props.position;
  const [vegetalPosition,setVegetalPosition] =useState([0,0]);
  const [vegetalNumber,setVegetalNumber] =useState("");
  const [bandaPosition,setBandaPosition] =useState([0,0]);

  var [globalResponse, setglobalresponse] = useState();
  var [elementspolygon,setPolygon]=useState([]);
  const[filtroGlobal,setFiltroGlobal]=useState();
  var customVegetalMarker =L.divIcon({
    className: stringacss,
    iconSize: [10, 10],
    html: `<div style="background-color:trasparent; border-radius: 50%; width:230px;height:30px;  justify-content:center;display:flex;"><b>Percentuale di Vegetazione ${vegetalNumber}%</b></div>`
  
  
  
  });

  var customBandaMarker =L.divIcon({
    className: stringacss,
    iconSize: [10, 10],
    html: `<div style="background-color:trasparent; border-radius: 50%; width:230px;height:30px;  justify-content:center;display:flex;"><br><br>Rapporto fra il Numero di terminazioni adsl ed il Numero di terminali telefonici (diversi indirizzi geografici)</div>`
  
  
  
  });

  if(props.search.length>0)
    {
     
     // alert("Searching for **"+props.search+" "+props.search.length);  
  
    //getDataSearch(props.search);
    }


  const handlePolygonChange = (polygonlocal) => {
    var rows = [];
    
    if (polygonlocal != null) {
    //  alert("Cambiato il poligono con" + polygonlocal);
      //setPolygon(polygonlocal); 
      if(polygonlocal) {
     //   console.log("Abbiamo valorizzato elementspolygon");
        setPolygon(polygonlocal);
        
      }
    
      var dataPolygon=polygonlocal;
   //   console.log(dataPolygon);

   //   console.log("Lunghezza:"+dataPolygon.length);

      for (let i = 0; i < dataPolygon.length; i++) {
        

        // note: we are adding a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        for(let c=0;c<dataPolygon[i].geometry.coordinates[0].length;c++)
        {
          var x=dataPolygon[i].geometry.coordinates[0][c][1];
          var y=dataPolygon[i].geometry.coordinates[0][c][0];
          dataPolygon[i].geometry.coordinates[0][c][0]=x;
          dataPolygon[i].geometry.coordinates[0][c][1]=y;
          console.log("x: "+x+" y:"+y);   
        }
       }

     
      


    } else {
    }
  };



  
  useEffect(() => {
    
   
    if (map) {
      if(position[0]===props.flyTo[0]&&position[1]===props.flyTo[1])
        {
          map.flyTo(position,18); }  
     //aggiornaMappa();
    // alert("Siamo in use effect di MyMap");
    //  alert("Stampiamo la getsearch da mymaps"+props.getSearch());
      if(props.getSearch()!=="")  getDataSearch(props.getSearch());
      props.resetSearch();
    //  console.log("Cambiate coordinate in" + position);
     
    }
  })

  
  const childToParent = (e) => {
    //alert("Stai cambiando filtro ricerca ==>" + e.currentTarget.id);
    setFiltroGlobal(e.currentTarget.id);
    //alert(e.currentTarget.id);
    if((e.currentTarget.id!="spesapubblica")&&(e.currentTarget.id!="inps")&&(e.currentTarget.id!="bandalarga")&&(e.currentTarget.id!="confini")) { props.setVisible(true)}
    else { props.setVisible(false)};
    
    props.settaCaricamento("Visible");
   // if (e.currentTarget.id) //console.log(e.currentTarget.id);
    filtroside = e.currentTarget.id;
    iconside = e.currentTarget.src;
    //colorside = e.currentTarget.getAttribute("color");
    //filtroside='[out:json][timeout:100];(nwr[amenity=school](around:10000.0,"+position+"););out body;>;out skel qt;';
    //console.log(filtroside);-
    if (filtroside.includes("inps")) {
      props.setInpsVisible(true); 
    } 
    
    if (filtroside.includes("spesapubblica")) {
      props.setSpVisible(true); 
    } else if (filtroside.includes("bandalarga")) {
     // getBandaLarga(position[0],position[1]); //alert("Adisoncum");
    } else
    if (filtroside.includes("confini")) {
      getConfiniAmministrativi(position[0],position[1]); //alert("Adisoncum");
    } else    if (filtroside.includes("adiconsum")) {
      getDataAdiconsum(); //alert("Adisoncum");
    } else  if (filtroside.includes("cultura")) {
      
      getDataLuoghiCultura(position[0],position[1],0.7); 
    }
    else  if (filtroside.includes("vegetazione")) {
      //alert("vegetazione");
      getDataVegetazione(position[0],position[1]); 
    }
    else  if (filtroside.includes("suolo")) {
     
    
      getDataSuolo(props.procom); 
     
      props.setSuoloVisible(true); 
      
    }
    else if(filtroside) getData();
  };

  const childToParentMap = (zoom) => {
    //alert("Stai cambiando filtro ==>");
   // console.log("invocato da ascoltatore mappa" + zoom);
    mapZoomGeneral = zoom;
    // if (mapZoomGeneral === 18) { proportionalGeneral = 21; visibleGeneral = "visible"; }
    // if (mapZoomGeneral === 17) { proportionalGeneral = 15; visibleGeneral = "hidden"; }
    // if (mapZoomGeneral === 16) { proportionalGeneral = 10; visibleGeneral = "hidden"; }
    // if (mapZoomGeneral === 15) { proportionalGeneral = 7; visibleGeneral = "hidden"; }
    // if (mapZoomGeneral <= 14) { proportionalGeneral = 5; visibleGeneral = "hidden"; }
    // //if (mapZoomGeneral < 14) { map.setZoom(14) }
    if (filtroside.includes("adiconsum")) {
      //getDataAdiconsum2(); //alert("Adisoncum");
    }
    else if(filtroside.includes("cultura"))
    {
      //getDataLuoghiCultura2(position[0],position[1],0.700);
    }
    // else getData2();
  };

 
  const [filtroSpeedLocal, setFiltrospeed] = useState([]);

 // useEffect(() => {
    // console.log("Siamo in use effect");
    //handleFilterChange(filtro);
    //aggiornaMappa(); 
    // Update the document title using the browser API
    // alert("Adesso "+filtroSpeedLocal);
    //aggiornaMappa();
   
    //alert("Searching for"+search);  
    //getDataSearch(search);
    
 // });

  const recuperoFiltro = (filtroSpeed) => {
    setFiltrospeed(filtroSpeed);
   // console.log("Abbiamo recuperato iconset dal filtro"+filtroSpeed);
   // console.log(filtroSpeed);
    for (let i = 0; i < filtroSpeed.length; i++) {
   //   console.log("Ecco icon set" + filtroSpeed[i].tipo + " " + filtroSpeed[i].iconSet);
      if (filtroSpeed[i].iconSet) {
        //     console.log(filtroSpeed[i].iconSet[0].amenity);
      }
    }
  }



  const filtro2 = [];
  const filtroicone = [];

  const [filtro, setFilter] = useState([]);


  const handleFilterChange = (filter) => {
    
   
    setFilter(filter);
    
  };


 

  function LocationMarker(props) {
   
    navigator.geolocation.getCurrentPosition(function (positionlocal) {

      
     if(props.comune===null)
     {
    props.setGpsState(false); 
    props.setGpsState(true);
    }
     //   console.log("Latitude navigator is :", positionlocal.coords.latitude);
     //   console.log("Longitude navigator is :", positionlocal.coords.longitude);
       
        // console.log(props.position);
     //   console.log(position[0]+" "+position[1]);
        if(((position[0]!==positionlocal.coords.latitude) && (position[1]!==positionlocal.coords.longitude)) && (props.getGpsState()))
       { props.setPosition([positionlocal.coords.latitude,positionlocal.coords.longitude]);
     //   console.log("Stiamop per fare il flyto da marker")
        map.flyTo([positionlocal.coords.latitude,positionlocal.coords.longitude], 18);
        

   //  map.flyTo(positionlocal.coords.latitude,positionlocal.coords.longitude);
   } 
   //console.log("Ecco il flyto");
   
   // else {console.log("Posizione uguale");}     
      // if (!primocaricamento) {
      //   position=positionlocal;
      //   map.locate();
      //   map.flyTo(position);
      //   setCaricamento(true);
        
      //      console.log("primo caricamento Latitude is :", position.coords.latitude);
      //      console.log("primo caricamento Longitude is :", position.coords.longitude);

      // }
      //invochiamo set
    
    });


    map = useMapEvents(
      {

        viewreset(e) {
        //     console.log("eccoci in reset");
        },
        zoom() {
          map.locate();
          props.childToParentMap(map.getZoom());

        },
        drag(e) {
       //   console.log("Siamo in Drag");
      //    console.log(props.comunepolygon);
          //console.log(e.target.getCenter());
          
            if(props.getGpsState()!==true)
          {
     //     console.log("Click");
     //     console.log(props.getGpsState());
          var position=e.target.getCenter();
        
 





          
          
        
        


       //  console.log("DRAG"+position.lat);
          
          //props.setPosition([positionInFunction.coords.latitude,positionInFunction.coords.longitude]);
            props.setPosition([position.lat,position.lng]); }
      //     console.log("Abbiamo fatto il set position");
        },
        click(e)
        {
          // if(props.getGpsState()!=true)
          // {
          // console.log("Click");
          // console.log(props.getGpsState());
          // var position=e.target.getCenter();

          // console.log(position.lat);
          
          // //props.setPosition([positionInFunction.coords.latitude,positionInFunction.coords.longitude]);
          //   props.setPosition([position.lat,position.lng]); }
        },
        locationfound(e) {

          //console.log("Found Location"+e.latlng+"Livello di zoom "+map.getZoom())
          //map.flyTo(e.latlng, map.getZoom())
        },
      })
    var stringacss = 'rounded-full p-1 flex justify-center';
    var iconPaladin = L.divIcon({
      className: stringacss,
      iconSize: [10, 10],
      html: `<div style="background-color:white; border-radius: 50%; width:30px;height:30px;  justify-content:center;display:flex;"><img  style="width:25px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${logo}" ></img></div>`



    });
    return position === null ? null : (

      <Marker position={position} icon={iconPaladin}>

      </Marker>
    );
  }





  //https://lz4.overpass-api.de/api/interpreter
  //http:overpass-api.paladin.expleoitalia.it/api/interpreter   
  //   axios("http://192.168.1.120:3001/api/overpass",


  //overpass(query, this.dataHandler, options);

  const getDataSearch = (filtro) => {

    props.setVisible(true);
  
    props.settaCaricamento("Visible");
    filtroside=""; 
    
    

    var elementspolygonlocal = [];
    handleFilterChange([]);
    
    
     iconside = "/IMG/generic.svg";
   // abbiamo variato colorside  colorside = "white";
   
     // alert("Siamo in getdatasearch"+filtro.replace(" ","+"));
    
     axios("https://paladin.expleoitalia.it/service/api/overpass",
       {
         method: 'post', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token }, data: "[out:json][timeout:100];(nwr[name~\".*"+filtro+".*\",i](around:3500.0," + position[0] + "," + position[1] + "););out body;>;out skel qt  ;"
      }, { timeout: 2000 }).then

       (response => {

    //    // console.log(keycloak.token);
        
       
         handlePolygonChange(elementspolygonlocal);
    //     //setglobalresponse(response);
        
    //     ////////////////////////////////////////////////////
         var osmtogeojson = require('osmtogeojson');
         var geojson = osmtogeojson(response.data);

         var elements2 = geojson.features;



    //   //  console.log("Convertito :" + elements2.length);
         for (let i = 0; i < elements2.length; i++) {
           if (elements2[i].geometry.type === "Polygon") {
             elementspolygonlocal.push(elements2[i]);
    //     //    console.log([elements2[i]]);
           }
         }
         for (let i = 0; i < elementspolygonlocal.length; i++) {

    //       //////////////////////////////////////////////////////

           var stringacss = 'rounded-full p-1 flex justify-center';
    //       //alert(stringacss);

    //       //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
    //       // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


           var iconatemporanea = "/IMG/generic.svg";
           var colortemporaneo = "white";


           filtroSpeedLocal.map(filtroSelectLocale => {
             console.log(elementspolygonlocal[i].properties.tags);
             if (elementspolygonlocal[i].properties.tags) {
               filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elementspolygonlocal[i].properties.tags.amenity)).map(iconatrovata => {
                 iconatemporanea = iconatrovata.iconamenity;
                 colortemporaneo = iconatrovata.color;
                 //console.log("eccoci2");
               }

               )
             }

           })
           if (elementspolygonlocal[i].properties.tags) {


             if (!iconatemporanea) iconatemporanea = iconside;
             if (!colortemporaneo) colortemporaneo = colorside;
             var iconMap = L.divIcon({
               className: stringacss,
               iconSize: [21, 21],
               html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elementspolygonlocal[i].properties.tags.name}</p>`

    //           // iconside viene acquisita dall'evento on click dalla sidebar
    //           // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


    //           //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    //           //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
    //           //  </button>`


             });

    //    //     console.log("AGGIUNTA IN FILTRO DI" + "lat: " + elementspolygonlocal[i].geometry.coordinates[0][0][0] + " lon: " + elementspolygonlocal[i].geometry.coordinates[0][0][1] + " tags: " + elementspolygonlocal[i].properties.tags + " icon: " + iconMap);
             filtro2.push({ lat: elementspolygonlocal[i].geometry.coordinates[0][0][1], lon: elementspolygonlocal[i].geometry.coordinates[0][0][0], tags: elementspolygonlocal[i].properties.tags, icon: iconMap });
    //         //            console.log("icon:"+iconside);
    //         //            console.log("color:"+colorside);
    //         //            console.log("icona"+iconatemporanea);

           }
         }
    //     ////////////////////////////////////////////////////

    //   //  console.log(globalResponse);
    //   //  console.log("-----sono io---------axios-------e filtro2push contiene" + filtro2.length + "elementi" + filtroside + "------------");
    //   //  console.log(response.data);
         const elements = response.data.elements;
         for (let i = 0; i < elements.length; i++) {
           try {
             if ((elements[i].lat) && (elements[i].lon) && (elements[i].tags.amenity) && (elements[i].tags.name)) {
    //       //  console.log("Cerchiamo per amenity"+elements[i].tags.amenity);
    //           // const   iconMap = L.icon({
    //           //   iconUrl: iconside,
    //           //   iconSize: [15, 15],
    //           // });

    //           //colorside='green';

               var stringacss = 'rounded-full p-1 flex justify-center';
    //           //alert(stringacss);

    //           //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
    //           // possiamo recuperare amenity dal json e cercare in iconSet l'elemento
  

    var iconatemporanea = "/IMG/generic.svg";
    var colortemporaneo = "white";

    //           //              console.log("prima di filtro per....")
    //           //              console.log("filtro per "+filtroside+"verificando"+filtroSpeedLocal.length);

               filtroSpeedLocal.map(filtroSelectLocale => {

                 filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elements[i].tags.amenity)).map(iconatrovata => {
                   iconatemporanea = iconatrovata.iconamenity;
                   colortemporaneo = iconatrovata.color;
                   console.log("eccoci trovata amenity"+iconatrovata.iconamenity);
                 }

                 )

               })
            
               if (!iconatemporanea) iconatemporanea = iconside;
               if (!colortemporaneo) colortemporaneo = colorside;
             

               var iconMap = L.divIcon({
                 className: stringacss,
                 iconSize: [21, 21],
                 html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elements[i].tags.name}</p>`

    //             // iconside viene acquisita dall'evento on click dalla sidebar
    //             // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


    //             //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    //             //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
    //             //  </button>`


               });


    //           // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
    //           // interrogando un vettore hashmap di icone amenity
               if (!elements[i].tags['addr:street']) {
    //              console.log("Non ci sono dati Query remota di reverse coding ***");
    //              //getStreet(elements[i].lat,elements[i].lon);
    //              console.log(elements[i]);
                  elements[i].tags['addr:street']="reversecoding";
    //          //    elements[i].tags['addr:street']=getStreet(elements[i].lat,elements[i].lon);
    //             // alert( elements[i].tags['addr:street']); promises


              }

               filtro2.push({ lat: elements[i].lat, lon: elements[i].lon, tags: elements[i].tags, icon: iconMap });
    //           //            console.log("icon:"+iconside);
    //           //            console.log("color:"+colorside);
    //           //            console.log("icona"+iconatemporanea);
             }

           } catch (e) { console.log('Error' + e); props.settaCaricamento("Hidden"); }
    //       //console.log("Adesso filtro2 contiene ultima" + filtro2.length);

         } handleFilterChange(filtro2); handlePolygonChange(elementspolygonlocal); props.settaCaricamento("Hidden");


    //     // var osmtogeojson = require('osmtogeojson');
    //     // var geojson = osmtogeojson(globalResponse.data);
    //     // const feature = geojson.features;
    //     // console.log("Convertito in getData:"+feature.length)
    //     // for (let i = 0; i < feature.length; i++) {

    //     //   if(feature[i].geometry.type=="Polygon")
    //     //   console.log([feature[i]]);
    //     // }
       }
       ).catch((err) => { console.log('fetch request failed: ', err) })
     
      axios("https://paladin.expleoitalia.it/service/api/nominatim?q="+filtro.replaceAll(" ","+")+"&format=jsonv2",
      {
        method: 'GET', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token }
      }, { timeout: 2000 }).then

      (response => {

   
        
        ////////////////////////////////////////////////////
        console.log("Response data for nominatim");
        console.log(response.data);

    


        var elementsnominatim=response.data;
    
        for (let i = 0; i < response.data.length; i++) {

          //////////////////////////////////////////////////////

          var stringacss = 'rounded-full p-1 flex justify-center';
          //alert(stringacss);

          //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
          // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


          var iconatemporanea = "/IMG/generic.svg";
          var colortemporaneo = "white";


          // filtroSpeedLocal.map(filtroSelectLocale => {
          //   console.log(response.data[i].type);
          //   if (elementsnominatim[i].type) {
          //     filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elementsnominatim[i].types)).map(iconatrovata => {
          //       iconatemporanea = iconatrovata.iconamenity;
          //       colortemporaneo = iconatrovata.color;
          //       //console.log("eccoci2");
          //     }

          //     )
          //   }

          // })
          if (elementsnominatim[i].category=="amenity") {


        //    if (!iconatemporanea) iconatemporanea = iconside;
        //    if (!colortemporaneo) colortemporaneo = colorside;
            var iconMap = L.divIcon({
              className: stringacss,
              iconSize: [21, 21],
              html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elementsnominatim[i].display_name}</p>`

              


            });
            
            var tagstemp =  {
             
              "amenity": elementsnominatim[i].type,
              
              "name": elementsnominatim[i].display_name,
              
            }

             console.log("AGGIUNTA IN FILTRO DI" + "lat: " + elementsnominatim[i].lat + " lon: " + elementsnominatim[i].lon + " tags: " + elementsnominatim[i].display_name + " icon: " + iconMap);
            filtro2.push({ lat: elementsnominatim[i].lat, lon: elementsnominatim[i].lon, tags: tagstemp, icon: iconMap });
            //            console.log("icon:"+iconside);
            //            console.log("color:"+colorside);
            //            console.log("icona"+iconatemporanea);

          }
        }
        ////////////////////////////////////////////////////

      //  console.log(globalResponse);
      //  console.log("-----sono io---------axios-------e filtro2push contiene" + filtro2.length + "elementi" + filtroside + "------------");
      //  console.log(response.data);
        const elements = response.data;
        for (let i = 0; i < elements.length; i++) {
          try {
            if ((elements[i].lat) && (elements[i].lon) && (elements[i].type) && (elements[i].display_name)) {
          //  console.log("Cerchiamo per amenity"+elements[i].tags.amenity);
              // const   iconMap = L.icon({
              //   iconUrl: iconside,
              //   iconSize: [15, 15],
              // });

              //colorside='green';

              var stringacss = 'rounded-full p-1 flex justify-center';
              //alert(stringacss);

              //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
              // possiamo recuperare amenity dal json e cercare in iconSet l'elemento
  

              var iconatemporanea = "/IMG/generic.svg";
              var colortemporaneo = "white";

              //              console.log("prima di filtro per....")
              //              console.log("filtro per "+filtroside+"verificando"+filtroSpeedLocal.length);

              // filtroSpeedLocal.map(filtroSelectLocale => {

              //   filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elements[i].category)).map(iconatrovata => {
              //     iconatemporanea = iconatrovata.iconamenity;
              //     colortemporaneo = iconatrovata.color;
              //     console.log("eccoci trovata amenity"+iconatrovata.iconamenity);
              //   }

              //   )

              // })
            
             // if (!iconatemporanea) iconatemporanea = iconside;
             // if (!colortemporaneo) colortemporaneo = colorside;
              var tagstemp =  {
             
                "amenity": elementsnominatim[i].type,
                
                "name": elementsnominatim[i].display_name,
                
              }

              var iconMap = L.divIcon({
                className: stringacss,
                iconSize: [21, 21],
                html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elements[i].display_name}</p>`

                // iconside viene acquisita dall'evento on click dalla sidebar
                // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


                //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
                //  </button>`


              });


              // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
              // interrogando un vettore hashmap di icone amenity
            

              filtro2.push({ lat: elements[i].lat, lon: elements[i].lon,tags:tagstemp,  icon: iconMap });
              console.log("lat:"+elements[i].lat+", lon: "+elements[i].lon+",  icon: "+iconMap );
              //            console.log("icon:"+iconside);
              //            console.log("color:"+colorside);
              //            console.log("icona"+iconatemporanea);
            }

          } catch (e) { console.log('Error' + e); props.settaCaricamento("Hidden"); }
          //console.log("Adesso filtro2 contiene ultima" + filtro2.length);

        } handleFilterChange(filtro2);  props.settaCaricamento("Hidden");


        // var osmtogeojson = require('osmtogeojson');
        // var geojson = osmtogeojson(globalResponse.data);
        // const feature = geojson.features;
        // console.log("Convertito in getData:"+feature.length)
        // for (let i = 0; i < feature.length; i++) {

        //   if(feature[i].geometry.type=="Polygon")
        //   console.log([feature[i]]);
        // }
      }
      ).catch((err) => { console.log('fetch request failed: ', err) }) 
  }

  const getDataSuolo = (codicecomune) => {
    if (codicecomune) {

       
        axios(`https://paladin.expleoitalia.it/service/api/sara/ispra/suolo/${codicecomune}`, {
            method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
        }, { timeout: 2000 }).then

            (response => {
                console.log("Tracciamento dati suolo-----------------------------------------------");
                console.log(response.data);
                props.setSuoloData(response.data);
                

            }
            );
    }
}

  // const getSpesaPubblica = (lat,lan) => {
  //   var elementspolygonlocal = [];

    
   
  //   axios(`https://paladin.expleoitalia.it/service/api/spesa_pubblica/previsione/${props.procom}`,   {
  //     method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token },
  //   }, { timeout: 2000 }).then
    
  //     (response => {

  //       console.log("response spesa pubblica");
  //       console.log(response);

  //      // setglobalresponse(response);
       

      

  //     }
  //     ).catch((err) => { console.log('fetch request failed: ', err) })
  //       props.settaCaricamento("Hidden");
  // }

  const getConfiniAmministrativi = (lat,lan) => {
    var elementspolygonlocal = [];

   
    axios(`https://paladin.expleoitalia.it/service/api/confini/comune/${lat}/${lan}`,   {
      method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token },
    }, { timeout: 2000 }).then
    
      (response => {

        var polygonlocal=response.data;
        if(response.data[0].geometry.type=="MultiPolygon")
        {
       for (let i = 0; i < polygonlocal[0].geometry.coordinates.length; i++) {
        for(let c=0;c<polygonlocal[0].geometry.coordinates[i].length;c++)
        {
          for(let d=0;d<polygonlocal[0].geometry.coordinates[i][c].length;d++)
        {
          console.log("coordinates["+i+"]["+c+"]["+d+"]");
          var x= polygonlocal[0].geometry.coordinates[i][c][d][0];
          var y= polygonlocal[0].geometry.coordinates[i][c][d][1];
          polygonlocal[0].geometry.coordinates[i][c][d][0]=y;
          polygonlocal[0].geometry.coordinates[i][c][d][1]=x;
          console.log("x "+x+"y "+y);  
        }
        }
      //  setComunePolygon(polygonlocal);
        props.setComunePolygon(polygonlocal);
      }
    }
    else
    {
      for(let c=0;c<polygonlocal[0].geometry.coordinates.length;c++)
      {
        for(let d=0;d<polygonlocal[0].geometry.coordinates[c].length;d++)
      {
        console.log("coordinates["+c+"]["+d+"]");
        var x= polygonlocal[0].geometry.coordinates[c][d][0];
        var y= polygonlocal[0].geometry.coordinates[c][d][1];
        polygonlocal[0].geometry.coordinates[c][d][0]=y;
        polygonlocal[0].geometry.coordinates[c][d][1]=x;
        console.log("x "+x+"y "+y);  
      }
      }
      //setComunePolygon(polygonlocal);
        props.setComunePolygon(polygonlocal);
    }
    map.flyTo(position,12);

       // Vecchia gestione su geomatrie condivise
        // var elements2 = response.data;
        //   for (let i = 0; i < elements2.length; i++) {
        //   if (elements2[i].geometry.type == "Polygon") {
        //     elementspolygonlocal.push(elements2
        //       [i]);
        //   }
        // }
        // handlePolygonChange(elementspolygonlocal);
      

          

    
      }
      ).catch((err) => { console.log('fetch request failed: ', err) })
      
      props.settaCaricamento("Hidden");
  }

  const getBandaLarga = (lat,lan) => {
    var elementspolygonlocal = [];
    axios(`https://paladin.expleoitalia.it/service/api/bandalarga/${lat}/${lan}`,   {
      method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token },
    }, { timeout: 2000 }).then
    

    
      (response => {

        console.log("response");
        console.log(response);
 
        setglobalresponse(response);
       

        var elements2 = response.data;
        console.log("ELEMENTS"); console.log(elements2);


       
            elementspolygonlocal.push(elements2);
            console.log("Per banda larga");
            console.log(elements2);
          
            props.setBandaPolygon(elements2);
       // handlePolygonChange(elementspolygonlocal);
    
 }
      ).catch((err) => { console.log('fetch request failed: ', err) })
      props.settaCaricamento("Hidden");
  }


  const getData = () => {
    var elementspolygonlocal = [];
   
    axios("https://paladin.expleoitalia.it/service/api/overpass",
      {
        method: 'post', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token }, data: "[out:json][timeout:100];(nwr[amenity~\"^(" + filtroside + ")\"](around:2500.0," + position[0] + "," + position[1] + "););out body;>;out skel qt;"
      }, { timeout: 2000 }).then
    
      (response => {

    //    console.log(keycloak.token);
        handlePolygonChange(elementspolygonlocal);
        console.log("response getdata");
        console.log(response);
        setglobalresponse(response);
        ////////////////////////////////////////////////////
        var osmtogeojson = require('osmtogeojson');
        var geojson = osmtogeojson(response.data);

        var elements2 = geojson.features;



      //  console.log("Convertito :" + elements2.length);
        for (let i = 0; i < elements2.length; i++) {
          if (elements2[i].geometry.type == "Polygon") {
            elementspolygonlocal.push(elements2[i]);
      //      console.log([elements2[i]]);
          }
        }
        for (let i = 0; i < elementspolygonlocal.length; i++) {

          //////////////////////////////////////////////////////

          var stringacss = 'rounded-full p-1 flex justify-center';
          var iconatemporanea = null;
          var colortemporaneo = null;


          filtroSpeedLocal.filter(filtrolocale => filtrolocale.tipo.includes(filtroside)).map(filtroSelectLocale => {
            if (elementspolygonlocal[i].properties.tags) {
              filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elementspolygonlocal[i].properties.tags.amenity)).map(iconatrovata => {
                iconatemporanea = iconatrovata.iconamenity;
                colortemporaneo = iconatrovata.color;
                //console.log("eccoci2");
              }

              )
            }

          })
          if (elementspolygonlocal[i].properties.tags) {

            if (!iconatemporanea) iconatemporanea = iconside;
            if (!colortemporaneo) colortemporaneo = colorside;
            var iconMap = L.divIcon({
              className: stringacss,
              iconSize: [21, 21],
              html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elementspolygonlocal[i].properties.tags.name}</p>`


            });

            filtro2.push({ lat: elementspolygonlocal[i].geometry.coordinates[0][0][1], lon: elementspolygonlocal[i].geometry.coordinates[0][0][0], tags: elementspolygonlocal[i].properties.tags, icon: iconMap });
     
          }
        }
        const elements = response.data.elements;
        for (let i = 0; i < elements.length; i++) {
          try {
            if ((elements[i].lat) && (elements[i].lon) && (elements[i].tags.amenity) && (elements[i].tags.name)) {


              var stringacss = 'rounded-full p-1 flex justify-center';


              var iconatemporanea = null;
              var colortemporaneo = null;

              filtroSpeedLocal.filter(filtrolocale => filtrolocale.tipo.includes(filtroside)).map(filtroSelectLocale => {

                filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elements[i].tags.amenity)).map(iconatrovata => {
                  iconatemporanea = iconatrovata.iconamenity;
                  colortemporaneo = iconatrovata.color;
                  //console.log("eccoci2");
                }

                )

              })

              if (!iconatemporanea) iconatemporanea = iconside;
              if (!colortemporaneo) colortemporaneo = colorside;
              var iconMap = L.divIcon({
                className: stringacss,
                iconSize: [21, 21],
                html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elements[i].tags.name}</p>`

              });


              if (!elements[i].tags['addr:street']) {



              }

              filtro2.push({ lat: elements[i].lat, lon: elements[i].lon, tags: elements[i].tags, icon: iconMap });
              //            console.log("icon:"+iconside);
              //            console.log("color:"+colorside);
              //            console.log("icona"+iconatemporanea);
            }

          } catch (e) { 
          }
          //console.log("Adesso filtro2 contiene ultima" + filtro2.length);

        } handleFilterChange(filtro2); handlePolygonChange(elementspolygonlocal);


   
      }
      ).catch((err) => { console.log('fetch request failed: ', err) })
     // props.setDataMain(props.DatiMain());
     map.flyTo(position,18); 
     props.settaCaricamento("Hidden");

  }

  const getData2 = () => {


    var response = globalResponse;
    ////////////////////////////////////////////////////
    var osmtogeojson = require('osmtogeojson');
   try { var geojson = osmtogeojson(globalResponse.data);
    var elements2 = geojson.features;
    var elementspolygonlocal = [];


    
     // console.log("Convertito :" + elements2.length);
      for (let i = 0; i < elements2.length; i++) {
        if (elements2[i].geometry.type == "Polygon") {
          elementspolygonlocal.push(elements2[i]);
         // console.log([elements2[i]]);
        }
      }
      handlePolygonChange(elementspolygonlocal);
      for (let i = 0; i < elementspolygonlocal.length; i++) {

        //////////////////////////////////////////////////////

        var stringacss = 'rounded-full p-1 flex justify-center';
        //alert(stringacss);

        //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
        // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


        var iconatemporanea = null;
        var colortemporaneo = null;


        filtroSpeedLocal.filter(filtrolocale => filtrolocale.tipo.includes(filtroside)).map(filtroSelectLocale => {
        //  console.log(elementspolygonlocal[i].properties.tags);
          if (elementspolygonlocal[i].properties.tags) {
            filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elementspolygonlocal[i].properties.tags.amenity)).map(iconatrovata => {
              iconatemporanea = iconatrovata.iconamenity;
              colortemporaneo = iconatrovata.color;
              //console.log("eccoci2");
            }

            )
          }

        })
        if (elementspolygonlocal[i].properties.tags) {

          if (!iconatemporanea) iconatemporanea = iconside;
          if (!colortemporaneo) colortemporaneo = colorside;
          var iconMap = L.divIcon({
            className: stringacss,
            iconSize: [21, 21],
            html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elementspolygon[i].properties.tags.name}</p>`

            // iconside viene acquisita dall'evento on click dalla sidebar
            // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


            //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
            //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
            //  </button>`


          });

         // console.log("AGGIUNTA IN FILTRO DI" + "lat: " + elementspolygonlocal[i].geometry.coordinates[0][0][0] + " lon: " + elementspolygonlocal[i].geometry.coordinates[0][0][1] + " tags: " + elementspolygonlocal[i].properties.tags + " icon: " + iconMap);
          filtro2.push({ lat: elementspolygonlocal[i].geometry.coordinates[0][0][1], lon: elementspolygonlocal[i].geometry.coordinates[0][0][0], tags: elementspolygonlocal[i].properties.tags, icon: iconMap });
          //            console.log("icon:"+iconside);
          //            console.log("color:"+colorside);
          //            console.log("icona"+iconatemporanea);

        }
      }
      ////////////////////////////////////////////////////

    //  console.log(globalResponse);
    //  console.log("-----sono io---------axios-------e filtro2push contiene" + filtro2.length + "elementi" + filtroside + "------------");
    //  console.log(response.data);
      const elements = response.data.elements;
      for (let i = 0; i < elements.length; i++) {
        try {
          if ((elements[i].lat) && (elements[i].lon) && (elements[i].tags.amenity) && (elements[i].tags.name)) {

            // const   iconMap = L.icon({
            //   iconUrl: iconside,
            //   iconSize: [15, 15],
            // });

            //colorside='green';

            var stringacss = 'rounded-full p-1 flex justify-center';
            //alert(stringacss);

            //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
            // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


            var iconatemporanea = null;
            var colortemporaneo = null;

            //              console.log("prima di filtro per....")
            //              console.log("filtro per "+filtroside+"verificando"+filtroSpeedLocal.length);

            filtroSpeedLocal.filter(filtrolocale => filtrolocale.tipo.includes(filtroside)).map(filtroSelectLocale => {

              filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elements[i].tags.amenity)).map(iconatrovata => {
                iconatemporanea = iconatrovata.iconamenity;
                colortemporaneo = iconatrovata.color;
                //console.log("eccoci2");
              }

              )

            })

            if (!iconatemporanea) iconatemporanea = iconside;
            if (!colortemporaneo) colortemporaneo = colorside;
            var iconMap = L.divIcon({
              className: stringacss,
              iconSize: [21, 21],
              html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elements[i].tags.name}</p>`

              // iconside viene acquisita dall'evento on click dalla sidebar
              // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


              //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
              //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
              //  </button>`


            });


            // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
            // interrogando un vettore hashmap di icone amenity
            if (!elements[i].tags['addr:street']) {
              console.log("Non ci sono dati Query remota di reverse coding");
              // getStreet(elements[i].lat,elements[i].lon);



            }

            filtro2.push({ lat: elements[i].lat, lon: elements[i].lon, tags: elements[i].tags, icon: iconMap });
            //            console.log("icon:"+iconside);
            //            console.log("color:"+colorside);
            //            console.log("icona"+iconatemporanea);
          }

        } catch (e) { console.log('Error' + e) }
       // console.log("Adesso filtro2 contiene ultima" + filtro2.length);

      } handleFilterChange(filtro2)

      // var osmtogeojson = require('osmtogeojson');
      // var geojson = osmtogeojson(globalResponse.data);
      // const feature = geojson.features;
      // console.log("Convertito in getData:"+feature.length)
      // for (let i = 0; i < feature.length; i++) {

      //   if(feature[i].geometry.type=="Polygon")
      //   console.log([feature[i]]);
      // }

    } catch (e) { }
  }
  const aggiornaMappa = () => {

    
    var response = globalResponse;
    ////////////////////////////////////////////////////
    var osmtogeojson = require('osmtogeojson');
   try { var geojson = osmtogeojson(globalResponse.data);
    var elements2 = geojson.features;
    var elementspolygonlocal = [];
 


    
     // console.log("Convertito :" + elements2.length);
      for (let i = 0; i < elements2.length; i++) {
        if (elements2[i].geometry.type == "Polygon") {
          elementspolygonlocal.push(elements2[i]);
     //     console.log([elements2[i]]);
        }
      }
      //handlePolygonChange(elementspolygonlocal);
      for (let i = 0; i < elementspolygonlocal.length; i++) {

        //////////////////////////////////////////////////////

        var stringacss = 'rounded-full p-1 flex justify-center';
        //alert(stringacss);

        //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
        // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


        var iconatemporanea = null;
        var colortemporaneo = null;


        filtroSpeedLocal.filter(filtrolocale => filtrolocale.tipo.includes(filtroside)).map(filtroSelectLocale => {
       //   console.log(elementspolygonlocal[i].properties.tags);
          if (elementspolygonlocal[i].properties.tags) {
            filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elementspolygonlocal[i].properties.tags.amenity)).map(iconatrovata => {
              iconatemporanea = iconatrovata.iconamenity;
              colortemporaneo = iconatrovata.color;
              //console.log("eccoci2");
            }

            )
          }

        })
        if (elementspolygonlocal[i].properties.tags) {

          if (!iconatemporanea) iconatemporanea = iconside;
          if (!colortemporaneo) colortemporaneo = colorside;
          var iconMap = L.divIcon({
            className: stringacss,
            iconSize: [21, 21],
            html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elementspolygon[i].properties.tags.name}</p>`

            // iconside viene acquisita dall'evento on click dalla sidebar
            // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


            //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
            //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
            //  </button>`


          });

         // console.log("AGGIUNTA IN FILTRO DI" + "lat: " + elementspolygonlocal[i].geometry.coordinates[0][0][0] + " lon: " + elementspolygonlocal[i].geometry.coordinates[0][0][1] + " tags: " + elementspolygonlocal[i].properties.tags + " icon: " + iconMap);
          filtro2.push({ lat: elementspolygonlocal[i].geometry.coordinates[0][0][1], lon: elementspolygonlocal[i].geometry.coordinates[0][0][0], tags: elementspolygonlocal[i].properties.tags, icon: iconMap });
          //            console.log("icon:"+iconside);
          //            console.log("color:"+colorside);
          //            console.log("icona"+iconatemporanea);

        }
      }
      ////////////////////////////////////////////////////

    //  console.log(globalResponse);
    //  console.log("-----sono io---------axios-------e filtro2push contiene" + filtro2.length + "elementi" + filtroside + "------------");
    //  console.log(response.data);
      const elements = response.data.elements;
      for (let i = 0; i < elements.length; i++) {
        try {
          if ((elements[i].lat) && (elements[i].lon) && (elements[i].tags.amenity) && (elements[i].tags.name)) {

            // const   iconMap = L.icon({
            //   iconUrl: iconside,
            //   iconSize: [15, 15],
            // });

            //colorside='green';

            var stringacss = 'rounded-full p-1 flex justify-center';
            //alert(stringacss);

            //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
            // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


            var iconatemporanea = null;
            var colortemporaneo = null;

            //              console.log("prima di filtro per....")
            //              console.log("filtro per "+filtroside+"verificando"+filtroSpeedLocal.length);

            filtroSpeedLocal.filter(filtrolocale => filtrolocale.tipo.includes(filtroside)).map(filtroSelectLocale => {

              filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elements[i].tags.amenity)).map(iconatrovata => {
                iconatemporanea = iconatrovata.iconamenity;
                colortemporaneo = iconatrovata.color;
                //console.log("eccoci2");
              }

              )

            })

            if (!iconatemporanea) iconatemporanea = iconside;
            if (!colortemporaneo) colortemporaneo = colorside;
            var iconMap = L.divIcon({
              className: stringacss,
              iconSize: [21, 21],
              html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elements[i].tags.name}</p>`

              // iconside viene acquisita dall'evento on click dalla sidebar
              // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


              //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
              //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
              //  </button>`


            });


            // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
            // interrogando un vettore hashmap di icone amenity
            if (!elements[i].tags['addr:street']) {
              console.log("Non ci sono dati Query remota di reverse coding");
              // getStreet(elements[i].lat,elements[i].lon);



            }

            filtro2.push({ lat: elements[i].lat, lon: elements[i].lon, tags: elements[i].tags, icon: iconMap });
            //            console.log("icon:"+iconside);
            //            console.log("color:"+colorside);
            //            console.log("icona"+iconatemporanea);
          }

        } catch (e) { console.log('Error' + e) }
        console.log("Adesso filtro2 contiene ultima" + filtro2.length);

      } handleFilterChange(filtro2)

      // var osmtogeojson = require('osmtogeojson');
      // var geojson = osmtogeojson(globalResponse.data);
      // const feature = geojson.features;
      // console.log("Convertito in getData:"+feature.length)
      // for (let i = 0; i < feature.length; i++) {

      //   if(feature[i].geometry.type=="Polygon")
      //   console.log([feature[i]]);
      // }

    } catch (e) { }
  }



  async function  getStreet  (lat, lon)  {
        
    var response=[];
    response=await axios.get(`http://nominatim-api.paladin.expleoitalia.it/reverse?format=json&lat=${lat}&lon=${lon}`,
      {
        method: 'get', headers: { '`Content-Type': 'text/plain' },
      }, { timeout: 200 }).then();

      console.log("ritorneremo1");
      console.log(response.data.address.road);
      var risposta=response.data.address.road;
      return("risposta");
   

  }
  const getDataAdiconsum2 = () => {
  
    var response=globalResponse;

      (response => {
        globalResponse = response;
        console.log("-----sono io---------axios-------" + filtroside + "------------");
        console.log(response.data);
        const elements = response.data;
       // alert(elements.length);
        for (let i = 0; i < elements.length; i++) {
          try {
            if ((elements[i].lat) && (elements[i].lon)) {

              // const   iconMap = L.icon({
              //   iconUrl: iconside,
              //   iconSize: [15, 15],
              // });

              //colorside='green';

              var stringacss = 'rounded-full p-1 flex justify-center';
              //alert(stringacss);

              //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
              // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


              var iconatemporanea = null;





              iconatemporanea = iconside;
              var iconMap = L.divIcon({
                className: stringacss,
                iconSize: [21, 21],
                html: `<div style="background-color:${colorside}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}+30px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${"Adiconsum"}</p>`

                // iconside viene acquisita dall'evento on click dalla sidebar
                // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


                //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
                //  </button>`


              });


              // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
              // interrogando un vettore hashmap di icone amenity


              filtro2.push({ lat: elements[i].lat, lon: elements[i].lon, tags: elements[i].comune, icon: iconMap });
              //            console.log("icon:"+iconside);
              //            console.log("color:"+colorside);
              //            console.log("icona"+iconatemporanea);
            }
          } catch (e) { console.log('Error' + e) }
        } handleFilterChange(filtro2)
      }
      ).catch((err) => { console.log('fetch request failed: ', err) })
  }
 
 
  const getDataAdiconsum = () => {
    var elementspolygonlocal=[];
    handlePolygonChange(elementspolygonlocal);
    axios("https://paladin.expleoitalia.it/service/api/adiconsum",
      {
        method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token },
      }, { timeout: 2000 }).then

      (response => {
        setglobalresponse(response);
        console.log("-----sono io---------axios-------" + filtroside + "------------");
        console.log(response.data);
        const elements = response.data;
       // alert(elements.length);
        for (let i = 0; i < elements.length; i++) {
          try {
            if ((elements[i].latitudine) && (elements[i].longitudine)) {

              // const   iconMap = L.icon({
              //   iconUrl: iconside,
              //   iconSize: [15, 15],
              // });

              //colorside='green';

              var stringacss = 'rounded-full p-1 flex justify-center';
              //alert(stringacss);

              //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
              // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


              var iconatemporanea = null;





              iconatemporanea = iconside;
              var iconMap = L.divIcon({
                className: stringacss,
                iconSize: [21, 21],
                html: `<div style="background-color:${colorside}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}+30px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${"Adiconsum"}</p>`

                // iconside viene acquisita dall'evento on click dalla sidebar
                // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


                //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
                //  </button>`


              });


              // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
              // interrogando un vettore hashmap di icone amenity


              filtro2.push({ lat: elements[i].latitudine, lon: elements[i].longitudine, tags: {"addr:street" : elements[i].indirizzo, name:elements[i].comune , email: elements[i].email,phone: elements[i].telefono}, icon: iconMap });
              //            console.log("icon:"+iconside);
              //            console.log("color:"+colorside);
              //            console.log("icona"+iconatemporanea);
            }
          } catch (e) { console.log('Error' + e) }
        } handleFilterChange(filtro2)
      }
      ).catch((err) => { console.log('fetch request failed: ', err) })
      props.setDataMain(props.DatiMain());
      props.settaCaricamento("Hidden");}
 
 



  const getDataLuoghiCultura2 = (lat,lan,raggio) => {
    var elementspolygonlocal=[];
    handlePolygonChange(elementspolygonlocal);

    console.log(lat);
    console.log(lan);
    console.log(raggio);
     {
        var response = globalResponse;
        console.log("-----sono io---------axios-------" + filtroside + "------------");
        console.log(response.data);
        const elements = response.data;
       // alert(elements.length);
        for (let i = 0; i < elements.length; i++) {
          try {
            if ((elements[i].geometry.coordinates[0]) && (elements[i].geometry.coordinates[1])) {

              // const   iconMap = L.icon({
              //   iconUrl: iconside,
              //   iconSize: [15, 15],
              // });

              //colorside='green';

              var stringacss = 'rounded-full p-1 flex justify-center';
              //alert(stringacss);

              //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
              // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


              var iconatemporanea = null;





              iconatemporanea = iconside;
              var iconMap = L.divIcon({
                className: stringacss,
                iconSize: [21, 21],
                html: `<div style="background-color:${colorside}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">Opere</p>`

                // iconside viene acquisita dall'evento on click dalla sidebar
                // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


                //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
                //  </button>`


              });


              // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
              // interrogando un vettore hashmap di icone amenity

              
              filtro2.push({ lat: elements[i].geometry.coordinates[1], lon: elements[i].geometry.coordinates[0], tags:{ name :elements[i].protectionAgency}, icon: iconMap,opere : elements[i].opere });
                        console.log("icon:"+iconside+"lat"+elements[i].geometry.coordinates[0]+"lan"+elements[i].geometry.coordinates[1]+"lable:"+ elements[i].label);
                          console.log("color:"+colorside);
                          console.log("icona"+iconatemporanea);
            }
          } catch (e) { console.log('Error' + e); props.settaCaricamento("Visible");}
        } handleFilterChange(filtro2)
      }
           
  }

  const getDataLuoghiCultura = (lat,lan,raggio) => {
    var elementspolygonlocal=[];
    handlePolygonChange(elementspolygonlocal);
    handleFilterChange([])
    //  console.log(lat);
  //  console.log(lan);
  //  console.log(raggio);
  //  console.log(keycloak.token);
    //axios(`http://192.168.1.120:3001/api/cultura/${lat}/${lan}/${raggio}`,
   axios(`https://paladin.expleoitalia.it/service/api/cultura/${lat}/${lan}/${raggio}`,
   //axios(`http://10.8.0.65:3001/api/cultura/${lat}/${lan}/${raggio}`,
      {
        method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token },
      }, { timeout: 2000 }).then

      (response => {
        setglobalresponse(response);
        console.log("-----sono io---------axios-------" + filtroside + "------------");
        console.log(response.data);
        const elements = response.data;
       // alert(elements.length);
        for (let i = 0; i < elements.length; i++) {
          try {
            if ((elements[i].geometry.coordinates[0]) && (elements[i].geometry.coordinates[1])) {

              // const   iconMap = L.icon({
              //   iconUrl: iconside,
              //   iconSize: [15, 15],
              // });

              //colorside='green';

              var stringacss = 'rounded-full p-1 flex justify-center';
              //alert(stringacss);

              //filtro side identifica il nostro elemento  nel vettore filtroSpeedLocal come tipo
              // possiamo recuperare amenity dal json e cercare in iconSet l'elemento


              var iconatemporanea = null;





              iconatemporanea = iconside;
              var iconMap = L.divIcon({
                className: stringacss,
                iconSize: [21, 21],
                html: `<div style="background-color:${colorside}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${"Opere"}</p>`

                // iconside viene acquisita dall'evento on click dalla sidebar
                // dalla stessa invochiamo un metodo sul figlio che ritorna la hashmap icone


                //  html :  `<button type="button" style="backgroundColor:${colorside}" className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-green-800 hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                //  <img  height="15" width="15" alt="filter.tipo" src="${iconside}"   />
                //  </button>`


              });


              // Stiamo creando le icone , invochiamo un metodo per il recupero dell'icona in base all'amenity
              // interrogando un vettore hashmap di icone amenity
        //      console.log("Facciamo una push di opere");
         //     console.log(elements[i].opere);
              
              filtro2.push({ lat: elements[i].geometry.coordinates[1], lon: elements[i].geometry.coordinates[0],  tags:{ name :elements[i].protectionAgency}, icon: iconMap,opere : elements[i].opere });
           //             console.log("icon:"+iconside+"lat"+elements[i].geometry.coordinates[0]+"lan"+elements[i].geometry.coordinates[1]+"lable:"+ elements[i].label);
           //               console.log("color:"+colorside);
           //               console.log("icona"+iconatemporanea);
            }
          } catch (e) { console.log('Error' + e) }
        } handleFilterChange(filtro2);
      }
      ).catch((err) => { console.log('fetch request failed: ', err) })
  }
  const getDataVegetazione = (lat,lan) => {
    var elementspolygonlocal=[];
    //handlePolygonChange(elementspolygonlocal);
    handleFilterChange([])
    //  console.log(lat);
  //  console.log(lan);
  //  console.log(raggio);
  //  console.log(keycloak.token);
    //axios(`http://192.168.1.120:3001/api/cultura/${lat}/${lan}/${raggio}`,
   axios(`https://paladin.expleoitalia.it/service/api/sara/copernicus/vegetazione/${lat}/${lan}`,
   //axios(`http://10.8.0.65:3001/api/cultura/${lat}/${lan}/${raggio}`,
      {
        method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + keycloak.token },
      }, { timeout: 2000 }).then

      (response => {
        setglobalresponse(response);
        console.log("-----sono io---------axios------vegetazione-" + filtroside + "------------");
        console.log(response.data);
        if(response.data)
        {
       
          setVegetalPosition([response.data.geometry.coordinates[1],response.data.geometry.coordinates[0]]);
          setVegetalNumber(Math.round(response.data.valore*100));
        }
        
      
      }
      ).catch((err) => { alert("Dati non risponibili in questa Area"); console.log('fetch request failed: ', err) })
  }


  // Gestione precedente senza Geometry
  const getDataOld = () => {



    var response = globalResponse;
   // console.log("-----sono io---------axios-------" + filtroside + "------------");
    //let data = new XMLParser().parseFromString(response.data);
  //  console.log(response.data);
    //let elements=[];
    const elements = response.data.elements;
    //console.log("Ecco gli elenmenti"+elements);
    for (let i = 0; i < elements.length; i++) {
      // console.log("elemento"+i+" "+elements[i].lat+" "+elements[i].lon);

      try {
        if ((elements[i].lat) && (elements[i].lon) && (elements[i].tags.amenity) && (elements[i].tags.name)) {

          var stringacss = 'rounded-full p-1 flex justify-center';
          var iconatemporanea = null;
          var colortemporaneo = null;
          filtroSpeedLocal.filter(filtrolocale => filtrolocale.tipo.includes(filtroside)).map(filtroSelectLocale => {

            filtroSelectLocale.iconSet.filter(iconalocale => iconalocale.amenity == (elements[i].tags.amenity)).map(iconatrovata => {
              iconatemporanea = iconatrovata.iconamenity;
              colortemporaneo = iconatrovata.color;
              //console.log("eccoci2");
            }

            )

          })

          if (!iconatemporanea) iconatemporanea = iconside;
          if (!colortemporaneo) colortemporaneo = colorside;

          var iconMap = L.divIcon({
            className: stringacss,
            iconSize: [21, 21],
            html: `<div style="background-color:${colortemporaneo}; border-radius: 50%; width:${proportionalGeneral + 7}px;height:${proportionalGeneral + 7}px;  justify-content:center;display:flex;"><img  style="width:${proportionalGeneral}px;margin-top:auto;margin-bottom:auto;margin-left:auto;margin-right:auto;display: block;" src="${iconatemporanea}" ></img></div><p style="visibility:${visibleGeneral}">${elements[i].tags.name}</p>`
          });
          filtro2.push({ lat: elements[i].lat, lon: elements[i].lon, tags: elements[i].tags, icon: iconMap });
        }
      } catch (e) {
        console.log('Error' + e)
      }
    }


    // Richiamiamo poi handrleFilterChange per aggiornare il filtro

    //  filtro[0]=[{lat:38.12074996992097,lan:13.356618818746634}];
    handleFilterChange(filtro2)

  }

  console.log("props.comunepolygon");
  console.log(props.comunepolygon);
  console.log("*****************elementspolygon****************");
  console.log(elementspolygon);







  if (filtro.length == 0) {
    //getData();

  }

   if (filtro.length > 0) {props.syncToParent(filtro)} else props.syncToParent([]);
   if ((filtro.length > 0)||(elementspolygon.length>0)||(props.comunepolygon.length>0)) {
    console.log("elementspolygon");
    console.log(elementspolygon);
    console.log("comunepolygon");
    console.log(props.comunepolygon);
    console.log("bandapolygon");
    console.log(props.bandapolygon);
    var color="green";
    if(props.bandapolygon.speed_down>10)
    {
      color="green";
    } else if(props.bandapolygon.speed_down>5)
    {
      color="yellow";
    }else color="red";
 //   if (filtro.length > 0) props.syncToParent(filtro);
 //   if ((filtro.length > 0)) {
    // var osmtogeojson = require('osmtogeojson');
    // var geojson = osmtogeojson(globalResponse.data);
    // const elements = geojson.features;
    // const elementspolygon = [];

    // console.log("Convertito :" + elements.length)
    // for (let i = 0; i < elements.length; i++) {

    //   if (elements[i].geometry.type == "Polygon") elementspolygon.push(elements[i]);
    //   console.log([elements[i].properties.tags.amenity]);
    // }
    // console.log("Seguono i marker");
  //  console.log(filtro);


    //return <tbody>{rows}</tbody>;


    return (



      <MapContainer center={props.position} zoom={18} zoomControl={false} style="https://tiles.stadiamaps.com/styles/alidade_smooth.json"  // Style URL; see our documentation for more options
      >
        <ZoomControl position="bottomleft"></ZoomControl>
      
      
         <LocationMarker  setGpsState={props.setGpsState} getGpsState={props.getGpsState} childToParentMap={childToParentMap} abilitato={true} position={props.position} setPosition={props.setPosition} comunepolygon={props.comunepolygon} currentPosition={position} />
       
      
        <LayersControl position="bottomleft">



          <LayersControl.Overlay checked name="Marker">
            <LayerGroup>
              <div>  {<SpeedDialXml currentfilter={filtroGlobal} comune={props.comune} className="z-[999]" filter={filtroicone} setVisible={props.setVisible} childToParent={childToParent} recuperoFiltro={recuperoFiltro} />}</div>
              {

              
                filtro.map((dato) => 
                  <Marker position={[dato.lat, dato.lon]} icon={dato.icon}   >


                    <Popup>
                        
                        {dato.tags.name} <br />  {dato.tags['addr:street']}  {dato.tags['addr:housenumber']} {dato.tags.email} <br /> {dato.tags.phone}
                       
                          {  
                             dato.opere==null ? ("") : ( <OpereModal opere={dato.opere}/>)
                            
                         }
                          {  
                             dato.tags.amenity!="school" ? ("") : (  <SchoolModal keycloak={keycloak} comune={props.comune} nomescuola={dato.tags.name} via={dato.tags['addr:street']}/>)
                            
                         }

                    </Popup>
                    {
              //      <div  style={{visibility: false}} ><OpereModal   visibility={false} opere={dato.opere} /> </div>
                     }
                    </Marker>
                      
                )

              }


             
              {/* Disegniamo graficamente i poligonoi nonostante saranno presenti i marker */}
              {/* <div data-dial-init className="fixed bottom-1/2 left-1  group z-[10000]">
                <div role="status">
                  <p>

                    {elementspolygon.map((datojson) =>



                      <>{datojson.geometry.coordinates[0]}</>

                    )}
                  </p>
                </div>
              </div> */}
              {elementspolygon.map((datojson) =>


//<p>{datojson.geometry.coordinates[0]}</p>

                
                <Polygon positions={datojson.geometry.coordinates[0]} /> 

//<Polygon  positions={multiPolygon} />

)}



{  filtroGlobal==="confini" ?
(
props.comunepolygon.map((datojson) =>
 datojson.geometry.coordinates.map((geometria) => 

//<p>{datojson.geometry.coordinates[0]}</p>
 <Polygon  fillOpacity={1.9} pathOptions={{ fillColor: colorside, fillOpacity : 0.2,strokeColor : colorside }} positions={geometria}/>
  
 )
)) : (<></>)

  }
   {  (filtroGlobal==="bandalarga")&&(props.bandapolygon) ?
(
// props.bandapolygon.map((datojson) =>
  props.bandapolygon.geometry.coordinates.map((geometria) => 

//  {datojson.geometry.coordinates[0]}
 <>
     <Marker key="bandalargatext"  position={props.customBandaMarker} icon={customBandaMarker}></Marker>
 <Polygon  fillOpacity={1.9} pathOptions={{ fillColor: color, fillOpacity : 0.2,strokeColor : colorside }} positions={geometria}/>
  </>
  ) 
//<>{props.bandapolygon}</> 
 //<><Polygon  fillOpacity={1.9} pathOptions={{ fillColor: colorside, fillOpacity : 0.2,strokeColor : colorside }} positions={props.bandapolygon.geometry.coordinates[0]}/>
//</>
//)

) : (<></>)

  } 
            
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="WorkArea">
            { <LayerGroup>
              <Marker key="Prova"  position={vegetalPosition} icon={customVegetalMarker}></Marker>
            
              <Circle
                center={vegetalPosition}
                pathOptions={{ fillColor: "#25ff33" }}
                radius={300}
                stroke={false}
              />
              <LayerGroup>
                <Circle
                  center={[51.51, -0.08]}
                  pathOptions={{ color: 'green', fillColor: 'green' }}
                  radius={1000}
                />
              </LayerGroup>
            </LayerGroup> }
          </LayersControl.Overlay>


          {/* <GeoJSON data={geojson} />
           */}



        </LayersControl>

        <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
       
      // url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
       attribution="&copy; OpenStreetMap contributors &copy;CARTO"
 
    />
        {}

      </MapContainer>
    )
  }
  else
    return (
      <MapContainer className=" h-full rounded-md block " layerControl={false} zoomControl={false} center={position} zoom={18}>
        <LocationMarker childToParentMap={childToParentMap} comunepolygon={props.comunepolygon} setGpsState={props.setGpsState} getGpsState={props.getGpsState} position={props.position} setPosition={props.setPosition} />
       />
        <div>  {<SpeedDialXml className="z-[999]" currentfilter={filtroGlobal} filter={filtroicone} childToParent={childToParent} recuperoFiltro={recuperoFiltro} />}</div>
        <TileLayer
    //  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
       
    // url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
     attribution="&copy; OpenStreetMap contributors &copy;CARTO"

    />
      </MapContainer>
    )
};
export default MyMap;