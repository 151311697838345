import { useState, useEffect } from "react";
import Keycloak from "keycloak-js";


import MainPageTest from "../Pages/MainPageTest";
const KeycloakAuth = () => {
  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  let data = JSON.stringify({
    query:
      '[out:json][timeout:100];(nwr[amenity~"^(bar|biergarten|cafe|fast_food|food_court|ice_cream|pub|restaurant)"](around:500.0,38.1164313,13.3635398););out body;>;out skel qt;',
  });
  let config = {
    method: "post",
    url: "overpass-service-api.paladin.expleoitalia.it/api/overpass",
    headers: {
      Authorization: "Bearer ",
      "Content-Type": "application/json",
    },
    data: data,
  };
  const handleKeycloak = () => {
    const keycloak = Keycloak("");
    keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
      setKeycloak(keycloak);
      setAuthenticated(authenticated);
    });
  };
  useEffect(() => {
    handleKeycloak();
  }, []);

  if (keycloak) {
    if (authenticated) {
      config.headers.Authorization += keycloak.token;
          return <MainPageTest keycloak={keycloak} />;
    } else return <div className="my-12">Unable to authenticate!</div>;
  }
  return (
    <>
      <div className="my-12">Loading....</div>
    </>
  );
};

export default KeycloakAuth;
