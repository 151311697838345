import { createSlice } from "@reduxjs/toolkit";

export const loadingState = createSlice({
  name: "loading",
  initialState: 0,
  reducers: {
    start: (state, action) => state + 1,
    end: (state, action) => state - 1,
  },
});
