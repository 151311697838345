import { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BasicTable from "./BasicTable";
import Table from "react-bootstrap/Table";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function Details( {match} ) {
    const { id, name } = match.params;
  
    return (
      <div>
        <h2>Details</h2>
        <p>ID: {id}</p>
        <p>Name: {name}</p>
      </div>
    );
  }