import { createSlice } from "@reduxjs/toolkit";

export const errorState = createSlice({
  name: "error",
  initialState: [],
  reducers: {
    add: (state, action) => [...state, action.payload],
    clear: (state, action) => [],
  },
});
