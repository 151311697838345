import { useState,useEffect } from "react";
import axios from "axios";
import L from "leaflet";

import bandalargaicon from "../IMG/networkspeed.png";



export default function BandaLargaInformation(props) 
{
    
    const [velocita, setVelocita] = useState("---"); 
    const [copertura,setCopertura] =useState("---");
    const [time,setTime]=useState(new Date().getTime());
    const [bandapolygon,setBandaPolygon]=useState();
    const [elaborazione,setElaborazione]=useState(false);
  //  var velocita="100Kbs";
  //  var copertura="100";

    var oraInvocazione;
    
    const invocaConfini= () => {
      // console.log("Invocato Confini");
       oraInvocazione=new Date().getTime();
      if((oraInvocazione-time)>3000){
   
       
      }

    

       if((oraInvocazione-time)>1000)
       {
        if(!bandapolygon)
        {
           console.log("Invocato per BandaPolygon==null");
           getBandaLarga(props.position[0],props.position[1]);
           setTime(new Date().getTime());
        }
        else
        {
       
        console.log(bandapolygon);
        var polygon = L.polygon(
          bandapolygon.geometry.coordinates
        );
         var m1 = L.marker([props.position[0], props.position[1]]); // Outside and north of polygon
         if(!polygon.getBounds().contains(m1.getLatLng()))
         {  //console.log("Possiamo invocare setConfini"); 
           getBandaLarga(props.position[0],props.position[1]);
           setTime(oraInvocazione);
         }
        }   
       
      
      }

    }
    
    
    const getBandaLarga = (lat,lan) => {
        var elementspolygonlocal = [];
        var firstCoord=false;
    
        axios(`https://paladin.expleoitalia.it/service/api/bandalarga/${lat}/${lan}`,   {
          method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
        }, { timeout: 2000 }).then
         (response => {
         var polygonlocal=response.data;
         //velocita=response.data.speed_down;
        // copertura=(response.data.adsl_cover*100).toFixed(0);
        
        setVelocita((response.data.speed_down).toFixed(2)+"Mb/s");
        setCopertura((response.data.adsl_cover*100).toFixed(0));
        
         var dataPolygon=polygonlocal;
        
         console.log(dataPolygon);
     
             // note: we are adding a key prop here to allow react to uniquely identify each
             // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
             for(let c=0;c<dataPolygon.geometry.coordinates[0].length;c++)
             {
              
                var x=dataPolygon.geometry.coordinates[0][c][1];
                var y=dataPolygon.geometry.coordinates[0][c][0];
                dataPolygon.geometry.coordinates[0][c][0]=x;
                dataPolygon.geometry.coordinates[0][c][1]=y;
               console.log("x: "+x+" y:"+y);   
               if (firstCoord==false)
               {
                 firstCoord=true;
              //   alert("SetMarkerPolygon "+x+y);
                 props.setMarkerPolygon([x,y]);
                 
               }
             }
            
            
            setBandaPolygon(dataPolygon);


      
             props.setBandaPolygon(polygonlocal);

            
            //handlePolygonChange(elementspolygonlocal);

            }
          ).catch((err) => { console.log('fetch request failed: ', err) })
      
       
             

      }

     //Recuperiamo name e comune da confini amministrativi
    // con UseSTATE per lo stato di tags.name e 
    // console.log(props.position);
   { 
    
     if((!bandapolygon)&&(!elaborazione))
        {
           //console.log("Invocato per ComunePolygon==null");
           getBandaLarga(props.position[0],props.position[1]);
           setTime(new Date().getTime());
           setElaborazione(true);
         }
     invocaConfini();
    // console.log("Ricaricata Localinformation");
    
    
    
    
    return (
    <div className="p-3">
     <button  className="px-4 text-white bg-green-600 rounded-full" >
      <div className="flex h-26 items-center justify-between">
      <div className="px-2">
      <img width='18' src={bandalargaicon}></img> 
      </div>
      <div>
      {velocita} | {copertura}%  
      </div>
      </div>
      </button>
    </div>           
     ) }
}