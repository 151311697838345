//import { Badge, FontAwesomeIcon, Sidebar } from "@fortawesome/react-fontawesome";
//import { faUtensils,faChurch,faHospital,faTrainTram,faFilm,faHeadset,faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "flowbite-react";
import { useState } from "react";
import axios from "axios";
import XMLParser from 'react-xml-parser';
import React from "react";

export default function SpeedDialXml(props) {
  
   const [filter,setData] = useState(props.filter);
   var iconsetGlobal=[];
   

  
   if(filter.length==0)
   {
   axios.get('filter.xml', {
    // "Content-Type": "application/xml; charset=utf-8"
    }).then(res => 
     {
      let data = new XMLParser().parseFromString(res.data);
      let filtro=[];
      let iconset=[];
      let myFilterList = data.getElementsByTagName('poi');
      //let myIconSet=[];
      
     for (let i=0; i < myFilterList.length ; i++) 
     {
        //console.log("filtro"+myFilterList[i].getElementsByTagName("tipo")[0].value);
        //console.log("tipo"+myFilterList[i].getElementsByTagName("tipo")[0].value);
        //console.log("icon"+myFilterList[i].getElementsByTagName("icon")[0].value);
        //console.log("color"+myFilterList[i].getElementsByTagName("color")[0].value);
        let myIconSet=myFilterList[i].getElementsByTagName('iconset');
       // console.log("lunghezza myiconste:"+myIconSet.length)
        for( let y=0; y<myIconSet.length;y++ )
        {
         try{
          
         iconset.push({color: myIconSet[y].getElementsByTagName("color")[0].value,amenity: myIconSet[y].getElementsByTagName("amenity")[0].value,iconamenity : myIconSet[y].getElementsByTagName("iconamenity")[0].value});   
        // console.log("ecco trovato"+myIconSet[y].getElementsByTagName("amenity")[0].color+" "+myIconSet[y].getElementsByTagName("amenity")[0].value+" "+myIconSet[y].getElementsByTagName("iconamenity")[0].value);
         }catch(e)
         {
        //  console.log("stampiano dopo lunghezza"+e);
         }
        }
        
        filtro.push({ descrizione:myFilterList[i].getElementsByTagName("descrizione")[0].value,tipo:myFilterList[i].getElementsByTagName("tipo")[0].value, icon: myFilterList[i].getElementsByTagName("icon")[0].value , color: myFilterList[i].getElementsByTagName("color")[0].value, iconSet:iconset});
      
        iconset=[];
      } 
     
      //console.log("Ecco la push su filtro "+filtro[0].tipo);
     //console.log("Ecco la push su filtro "+filtro[0].iconSet[0].iconamenity);
     //console.log("Ecco la push su filtro "+filtro[0].iconSet[0].amenity);
     //console.log("Ecco la push su filtro "+filtro[0].iconSet[1].iconamenity);
     //console.log("Ecco la push su filtro "+filtro[0].iconSet[1].amenity);
     handleFilterChange(filtro);
     props.recuperoFiltro(filtro);
     for (let i = 0; i < filtro.length; i++) 
     {
   //   console.log(filtro[i].tipo+" "+filtro[i].iconSet);
     }
    
    });
     
   }
 
   
   
   
   const handleFilterChange =(filter) => 
   {
    setData(filter);
    //console.log("Abbiamo cambiato lo stato"+filter[1].tipo+" "+filter[1].icon);
   };
   const data = 'lorem <b>isum</b>';
   const attrValue = "my-button";
   
   <p  ></p>
   //{[filter.iconset[0].amenity]:filter.iconset[0].iconamenity}
  
   

  const listitems= filter.map((filter)=>
  
 <div id="speed-dial-menu-top-right"   className="flex flex-col items-center  mt-1 space-y-1 z-[10000] ">
       
     {  props.currentfilter===filter.tipo ? 
    (
      <button  aria-label={filter.tipo}  color="black" data-dial-toggle="speed-dial-menu-top-right"  aria-controls="speed-dial-menu-top-right" aria-expanded="false" style={{backgroundColor:filter.color}} className="flex items-center justify-center  text-white bg rounded-full w-10 h-10 stroke-white hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-green-800 focus:outline-none dark:focus:ring-blue-800">
   
      <img id={filter.tipo}  title={filter.descrizione} height="30" width="30"    alt={filter.tipo} src={filter.icon} color={filter.color} onClick={(e) => props.childToParent(e)}  />
      </button>
    ) : 
    (
      <button  aria-label={filter.tipo}  color="black" data-dial-toggle="speed-dial-menu-top-right"  aria-controls="speed-dial-menu-top-right" aria-expanded="false" style={{backgroundColor:filter.color}} className="flex items-center justify-center  text-white bg rounded-full w-8 h-8 stroke-white hover:bg-green-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-green-800 focus:outline-none dark:focus:ring-blue-800">
   
      <img id={filter.tipo}  title={filter.descrizione} height="20" width="20"    alt={filter.tipo} src={filter.icon} color={filter.color} onClick={(e) => props.childToParent(e)}  />
      </button>
    )  }
      
    
  
  
</div>)
  
    return (
        <div data-dial-init className="fixed bottom-60% right-1 bottom-10 group z-[10000]">

            {listitems}
          
         </div>

        );
}

