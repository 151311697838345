import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setInviteInfo } from "../State/RegistrationFormSlice";
import { useNavigate } from "react-router-dom";

import { setPersonalInfo } from "../State/RegistrationFormSlice";
import eyeSolid from "../IMG/eyeSolid.svg";
import eyeSlash from "../IMG/eyeSlash.svg";
import axios from "axios";
import XMLParser from "react-xml-parser";
import { setSettingInfo } from "../State/RegistrationFormSlice";
import { Modal } from "flowbite-react";
import circleExclamation from "../IMG/circleExclamation.png";
import Privacy from "../IMG/privacy.png";
import circleCheck from "../IMG/circleCheck.png";

const Registration = () => {
  const [section, setSection] = useState("InviteForm");

  const [cod_invito, setCodInvito] = useState("");
  const [codice_sede, setcodice_sede] = useState("");
  const [id_tessera, setid_tessera] = useState("");
  const [codInvitoError, setCodInvitoError] = useState(false);
  const [codice_sedeError, setcodice_sedeError] = useState(false);
  const [id_tesseraError, setid_tesseraError] = useState(false);
  const [inviteError, setInviteError] = useState(false);

  const [email, setEmailValue] = useState("");
  const [cEmail, setCEmailValue] = useState("");
  const [password, setPasswordValue] = useState("");
  const [cPassword, setCPasswordValue] = useState("");
  const [nome, setNomeValue] = useState("");
  const [cognome, setCognomeValue] = useState("");
  const [data_nascita, setDataNascitaValue] = useState("");
  const [codice_fiscale, setCodiceFiscaleValue] = useState("");
  const [telefono, setTelefonoValue] = useState("");
  const [citta, setCittaValue] = useState("");
  const [provincia, setProvinciaValue] = useState("");
  const [indirizzo, setIndirizzoValue] = useState("");
  const [civico, setCivicoValue] = useState("");
  const [tipo_indirizzo, setTipoIndirizzoValue] = useState("");
  const [cap, setCapValue] = useState("");
  const [imgPass, setImgPass] = useState(true);
  const [imgCPass, setCImgPass] = useState(true);

  const [emailError, setEmailError] = useState(false);
  const [cEmailError, setCEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cPasswordError, setCPasswordError] = useState(false);
  const [nomeError, setNomeError] = useState(false);
  const [cognomeError, setCognomeError] = useState(false);
  const [dataNascitaError, setDataNascitaError] = useState(false);
  const [codiceFiscaleError, setCodiceFiscaleError] = useState(false);
  const [telefonoError, setTelefonoError] = useState(false);
  const [cittaError, setCittaError] = useState(false);
  const [provinciaError, setProvinciaError] = useState(false);
  const [indirizzoError, setIndirizzoError] = useState(false);
  const [civicoError, setCivicoError] = useState(false);
  const [tipoIndirizzoError, setTipoIndirizzoError] = useState(false);
  const [capError, setCapError] = useState(false);
  const [personalError, setPersonalError] = useState(false);
  const [sigla, setSigla] = useState([]);
  const tipiIndirizzo = ["Casa", "Ufficio", "Prova1", "Prova2"];
  const [comuniArrayState, setComuniArrayState] = useState([]);
  const [codici, setCodici] = useState([]);
  const [dis, setDis] = useState(true);

  const [privacy, setPrivacyValue] = useState(false);
  const [newsletter, setNewsletterValue] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  //Visualizza o nascondi modal
  const [modal, setModal] = useState(false);
  //Messaggio da visualizzare nel modal.
  const [textModal, setTextModal] = useState("");
  //Array che setta il modal.
  //0:indica l'immagine da visualizzare(error, check, privacy)
  //1:boolean che indica il tipo di bottone (true: naviga, false:chiude il modale)
  //2:testo nel bottone
  //3:path da seguire se naviga
  const [modalItems, setModalItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInviteNavigate = () => {
    setSection("InviteForm");
  };

  const handlePersonalNavigate = () => {
    setSection("PersonalForm");
  };
  const handleSettingNavigate = () => {
    setSection("SettingForm");
  };

  const handleFinaleNavigate = () => {
    setSection("Finale");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleInviteSubmit = (event) => {
    event.preventDefault();

    if (!cod_invito || !codice_sede || !id_tessera) {
      if (!cod_invito) {
        console.log("Codice invito vuoto");
        setCodInvitoError("Campo obbligatorio");
      } else {
        setCodInvitoError(false);
        setInviteError(false);
      }
      if (!codice_sede) {
        console.log("Codice sede vuoto");
        setcodice_sedeError("Campo obbligatorio");
      } else {
        setcodice_sedeError(false);
        setInviteError(false);
      }

      if (!id_tessera) {
        console.log("La tessera è vuota");
        setid_tesseraError("Campo obblogatorio");
      } else {
        setid_tesseraError(false);
        setInviteError(false);
      }
      return setInviteError("Completare i campi vuoti");
    }
    const handleSubmit1 = () => {
      dispatch(setInviteInfo({ cod_invito, codice_sede, id_tessera }));
    };

    // Controllo se ci sono errori
    if (codInvitoError || codice_sedeError || id_tesseraError) {
      setInviteError("Ci sono alcuni errori nel form");
      return;
    } else if (!codInvitoError && !codice_sedeError && !id_tesseraError) {
      setInviteError(false);
      handleSubmit1({ cod_invito, codice_sede, id_tessera });
      handlePersonalNavigate();
    }
  };

  const handlePersonalSubmit = (event) => {
    event.preventDefault();

    if (
      !nome ||
      !cognome ||
      !email ||
      !cEmail ||
      !password ||
      !cPassword ||
      !data_nascita ||
      !codice_fiscale ||
      !telefono ||
      !citta ||
      !provincia ||
      !indirizzo ||
      !civico ||
      !tipo_indirizzo ||
      !cap
    ) {
      if (!nome) {
        console.log("Nome vuoto");
        setNomeError("Inserire il proprio nome");
      } else {
        setNomeError(false);
      }
      if (!cognome) {
        console.log("Cognome vuoto");
        setCognomeError("Inserire il proprio cognome");
      } else {
        setCognomeError(false);
      }
      if (!email) {
        console.log("Email vuota");
        setEmailError("Inserire la propria email");
      } else {
        setEmailError(false);
      }
      if (!cEmail) {
        console.log("Conferma Email vuota");
        setCEmailError("Inserire nuovamente la propria email");
      } else {
        setCEmailError(false);
      }
      if (!password) {
        console.log("Password vuota");
        setPasswordError("Inserire una password");
      } else {
        setPasswordError(false);
      }
      if (!cPassword) {
        console.log("Conferma Password vuota");
        setCPasswordError("Inserire nuovamente la Password");
      } else {
        setCPasswordError(false);
      }
      if (!data_nascita) {
        console.log("Data di nascita mancante");
        setDataNascitaError("Inserire la propria data di nascita");
      } else {
        setDataNascitaError(false);
      }
      if (!codice_fiscale) {
        console.log("Codice fiscale vuoto");
        setCodiceFiscaleError("Inserire il proprio codice fiscale");
      } else {
        setCodiceFiscaleError(false);
      }
      if (!telefono) {
        console.log("Numero di telefono vuoto");
        setTelefonoError("Inserire il proprio numero di telefono");
      } else {
        setTelefonoError(false);
      }
      if (!citta) {
        console.log("Città vuota");
        setCittaError("Inserire la propria città");
      } else {
        setCittaError(false);
      }
      if (!provincia) {
        console.log("Provincia vuota");
        setProvinciaError("Inserire la propria provincia");
      } else {
        setProvinciaError(false);
      }
      if (!indirizzo) {
        console.log("Indirizzo vuoto");
        setIndirizzoError("Inserire il proprio indirizzo");
      } else {
        setIndirizzoError(false);
      }
      if (!civico) {
        console.log("Civico vuoto");
        setCivicoError("Obbligatorio");
      } else {
        setCivicoError(false);
      }
      if (!tipo_indirizzo) {
        console.log("tipo di indirizzo vuoto");
        setTipoIndirizzoError("Indicare il tipo di indirizzo inserito");
      } else {
        tipoIndirizzoError(false);
      }
      if (!cap) {
        console.log("CAP vuoto");
        setCapError("Obbligatorio");
      } else {
        setCapError(false);
      }
      return setPersonalError("Completare i campi vuoti");
    }

    if (
      nomeError.valueOf(true) ||
      cognomeError.valueOf(true) ||
      emailError.valueOf(true) ||
      cEmailError.valueOf(true) ||
      passwordError.valueOf(true) ||
      cPasswordError.valueOf(true) ||
      dataNascitaError.valueOf(true) ||
      codiceFiscaleError.valueOf(true) ||
      telefonoError.valueOf(true) ||
      cittaError.valueOf(true) ||
      provinciaError.valueOf(true) ||
      indirizzoError.valueOf(true) ||
      civicoError.valueOf(true) ||
      tipoIndirizzoError.valueOf(true) ||
      capError.valueOf(true)
    ) {
      setPersonalError("Alcuni campi non sono compilati correttamente");
      return;
    } else {
      setPersonalError(false);
      handleSubmit2({
        nome,
        cognome,
        email,
        password,
        data_nascita,
        codice_fiscale,
        telefono,
        citta,
        provincia,
        indirizzo,
        civico,
        tipo_indirizzo,
        cap,
      });
      handleSettingNavigate();
    }
  };

  const handleSubmit2 = () => {
    dispatch(
      setPersonalInfo({
        nome,
        cognome,
        email,
        password,
        data_nascita,
        codice_fiscale,
        telefono,
        citta,
        provincia,
        indirizzo,
        civico,
        tipo_indirizzo,
        cap,
      })
    );
  };

  const handleNavigate = () => {
    navigate(modalItems[3]);
  };

  const handleSettingSubmit = (event) => {
    event.preventDefault();
    if (!privacy) {
      setTextModal("La privacy è obbligatoria.");
      setModalItems(["error", false, "Ok!"]);
      setModal(true);
      setPrivacyError(true);
    } else {
      dispatch(setSettingInfo({ privacy, newsletter }));
      handleFinaleNavigate();
    }
  };

  let inputData = useSelector((state) => state.registration);
  let data = JSON.stringify(inputData);

  var config = {
    method: "post",
    url: "https://paladin.expleoitalia.it/service/api/users/register",
    timeout: 10000,
    // url: "http://192.168.1.120:3000/api/users/register",
    // url: "http://10.8.0.65:3000/api/users/register",

    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const handleSubmit4 = () => {
    setLoading(true);

    handleModal();
    setModalItems(["loading", false]);

    axios(config)
      .then(function (response) {
        console.log(response.status);
        handleResponse(response.status);

        return;
      })
      .catch((error) => {
        console.log(error);
        handleResponse(error.response.status);
      });
    setLoading(false);
  };

  const handleResponse = (status) => {
    console.log(status);
    if (status >= 400 && status <= 499) {
      setTextModal(
        "Campi non compilati correttamente. Registrazione non avvenuta."
      );
      handleModal();
      setModalItems(["error", false, "Ok!"]);
    } else if (status >= 200 && status <= 299) {
      setTextModal(
        "Registrazione avvenuta con successo!!  A breve ti arriverà l'email di conferma."
      );
      handleModal();
      setModalItems(["check", true, "Login", "/login"]);
    } else if (status >= 500 && status <= 599) {
      setTextModal(
        "Il server non raggiungibile al momento. Riprovare in un secondo momento."
      );
      handleModal();
      setModalItems(["error", true, "Torna alla Home", "/"]);
    } else {
      setTextModal("Riprova più tardi!");
      handleModal();
      setModalItems(["error", true, "Torna alla Home", "/"]);
    }
  };

  const fetchDataProvince = () => {
    let provinceArray = [];
    axios
      .get("../../provinceList.xml")
      .then((response) => {
        const xml = new XMLParser().parseFromString(response.data);
        const province = xml.getElementsByTagName("provincia");

        for (let i = 0; i < province.length; i++) {
          provinceArray.push(province[i].attributes.nome);
        }
        provinceArray.sort();
        setSigla(provinceArray);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  const fetchDataComuni = () => {
    let comuniArray = [];
    axios
      .get("../../ProvinceComuni.xml")
      .then((response) => {
        const xml = new XMLParser().parseFromString(response.data);
        const province = xml.getElementsByTagName("provincia");
        for (let i = 0; i < province.length; i++) {
          if (provincia === province[i].attributes.nome) {
            const comune = province[i].getElementsByTagName("comune");
            for (let j = 0; j < comune.length; j++) {
              comuniArray.push(comune[j].attributes.nome);
            }
          }
        }
        comuniArray.sort();
        setComuniArrayState(comuniArray);
        console.log(comuniArrayState);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  const fetchDataCodici = () => {
    axios
      .get("../../codici.json")
      .then((response) => setCodici(response.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchDataProvince();
    fetchDataCodici();
  }, []);

  const showPwd = (event) => {
    event.preventDefault();
    var input = document.getElementById("password");
    if (input.type === "password") {
      input.type = "textarea";
    } else {
      input.type = "password";
    }
    setImgPass(!imgPass);
  };
  const showCPwd = (event) => {
    event.preventDefault();
    var input = document.getElementById("cPassword");
    if (input.type === "password") {
      input.type = "textarea";
    } else {
      input.type = "password";
    }
    setCImgPass(!imgCPass);
  };

  const styleDate = {
    color: data_nascita === "" ? "var(--pld-placeholder)" : "var(--pld-text)",
  };

  const styleTipo = {
    color: tipo_indirizzo === "" ? "var(--pld-placeholder)" : "var(--pld-text)",
  };

  const styleCodiceSede = {
    color: codice_sede === "" ? "var(--pld-placeholder)" : "var(--pld-text)",
  };

  const handlePrivacyCheck = (event) => {
    setPrivacyValue(event.target.checked);
    console.log(event.target.checked);
    console.log(newsletter);
  };
  const handleNewsCheck = (event) => {
    console.log(privacy);
    setNewsletterValue(event.target.checked);
    console.log(event.target.checked);
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handlePrivacyPolicies = () => {
    handleModal();
    setModalItems(["privacy", false, "Ho letto!"]);
    async function fetchText() {
      const response = await fetch("../../PrivacyPolicies.txt");
      const text = await response.text();
      setTextModal(text);
    }
    fetchText();
  };

  const handleCodiceInvitoBlur = () => {
    if (!codici.includes(cod_invito)) {
      setCodInvitoError("Il codice inserito non è valido.");
      return;
    }
    setCodInvitoError(false);
  };

  const handleId_tesseraBlur = () => {
    if (!/^[0-9]{5,5}$/.test(id_tessera)) {
      setid_tesseraError("L'ID tessera contiene 5 cifre!");
      return;
    }
    setid_tesseraError(false);
  };

  const handlecodice_sedeBlur = () => {
    if (!/^(sede)[1-3]$/.test(codice_sede)) {
      setcodice_sedeError("Indicare la sede Adiconsum!");
      return;
    }
    setcodice_sedeError(false);
  };

  const handleTelFocus = () => {
    if (!telefono) {
      setTelefonoValue("+39");
    }
  };

  const handleNomeBlur = () => {
    if (!/^[A-Z][a-zA-Z\s-]+(àèéòìù-)*/.test(nome)) {
      setNomeError("Inserire il proprio nome con l'iniziale maiuscola.");
      return;
    }
    setNomeError(false);
  };

  const handleCognomeBlur = () => {
    // if (!/^[A-Z][a-zA-Z\s-]+(àèéòìù-)*/.test(cognome)) {
    //   setCognomeError("Inserire il proprio cognome con l'iniziale maiuscola.");
    return;
    // }
    // setNomeError(false);
  };

  const handleEmailBlur = () => {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailError("Inserire un'email valida");
      return;
    }
    setEmailError(false);
  };
  const handleCEmailBlur = () => {
    if (email !== cEmail || !cEmail) {
      setCEmailError("Inserisci nuovamente la tua email");
    } else if (email === cEmail) setCEmailError(false);
  };

  const handlePasswordBlur = () => {
    if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\\-\\.]).{8,64}$/.test(
        password
      )
    ) {
      setPasswordError(
        "La password deve contenere almeno 8 caratteri tra cui: una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale."
      );
      return;
    }
    setPasswordError(false);
  };

  const handleCPasswordBlur = () => {
    if (password === cPassword) {
      setCPasswordError(false);
    } else {
      setCPasswordError("La password inserita non corrisponde.");
      return;
    }
  };

  const handleDataNascitaBlur = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const nascita = new Date(data_nascita);
    const risultato = today - nascita;
    const ageInYears = risultato / (1000 * 60 * 60 * 24 * 365.25);
    console.log({ ageInYears });

    if (ageInYears < 18) {
      setDataNascitaError("Per iscriverti devi avere almeno 18 anni!");
      return;
    }
    setDataNascitaError(false);
  };

  const handleCodiceFiscaleBlur = () => {
    if (
      !/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z]{1}[0-9]{2}[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{1}$/.test(
        codice_fiscale
      )
    ) {
      setCodiceFiscaleError("Inserire un codice fiscale valido");
      return;
    }
    setCodiceFiscaleError(false);
  };

  const handleTelefonoBlur = () => {
    if (!/^(\+?\d{1,3}|\d{2})\d{7,9}$/.test(telefono)) {
      setTelefonoError("Inserire un numero di telefono valido.");
      return;
    }
    setTelefonoError(false);
  };

  const handleCittaBlur = () => {
    if (!comuniArrayState.includes(citta)) {
      setCittaError("Inserire una Città valida");
      return;
    }
    setCittaError(false);
  };

  const handleProvinciaBlur = () => {
    if (!sigla.includes(provincia)) {
      setProvinciaError("Inserire una Provincia valida");
      setDis(true);
      return;
    }
    setProvinciaError(false);
    setDis(false);
    fetchDataComuni();
  };

  const handleIndirizzoBlur = () => {
    if (!/^[a-zA-Z\s-]+$/.test(indirizzo)) {
      setIndirizzoError("Inserire un indirizzo valido");
      return;
    }
    setIndirizzoError(false);
  };

  const handleCivicoBlur = () => {
    if (!/\d/.test(civico)) {
      setCivicoError("Non valido");
      return;
    }
    setCivicoError(false);
  };

  const handleTipoIndirizzoBlur = () => {
    if (!tipiIndirizzo.includes(tipo_indirizzo)) {
      setTipoIndirizzoError("Inserire un tipo di indirizzo valido");
      return;
    }
    setTipoIndirizzoError(false);
  };

  const handleCapBlur = () => {
    if (!/^[0-9]{5,5}$/.test(cap)) {
      setCapError("Inserire un CAP valido");
      return;
    }
    setCapError(false);
  };

  const handlePrivacyBlur = () => {
    if (privacy) {
      setPrivacyError(false);
    }
  };

  const handleClickM = () => {
    if (modalItems[1] === true) {
      handleNavigate();
    } else {
      handleModal();
    }
  };

  const maskPass = (password) => {
    return password.replace(/./g, "*");
  };

  return (
    <>
      <h1 className="w-full text-4xl flex justify-center pldText">
        <b>Registrazione</b>
      </h1>
      <div className="flex justify-center">
        <div
          id="pldProgress"
          className="flex items-center px-2 py-4 top-8 relative"
        >
          <div className="absolute w-full z-[99] pldText text-center">
            {section === "InviteForm"
              ? "Pagina 1 di 3"
              : section === "PersonalForm"
              ? "Pagina 2 di 3"
              : section === "SettingForm"
              ? "Pagina 3 di 3"
              : section === "Finale"
              ? "Completato!"
              : null}
          </div>
          <div
            id="pldBar"
            className="flex justify-center absolute w-full"
            style={
              section === "InviteForm"
                ? { width: "33%" }
                : section === "PersonalForm"
                ? { width: "66%" }
                : section === "SettingForm"
                ? { width: "99%" }
                : section === "Finale"
                ? { width: "100%" }
                : null
            }
          ></div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-start min-h-[80%]">
        <div
          className={
            section === "PersonalForm"
              ? "max-w-md space-y-6 w-full max-w-4xl pt-8"
              : "max-w-md space-y-6 w-full"
          }
        >
          <br></br>
          <div className="formArea px-8 pb-8 pt-8 rounded-lg">
            {section === "InviteForm" ? (
              <>
                <form
                  className="px-4 pt-4 pb-2 grid gap-6 grid-cols-1 justify-between"
                  onSubmit={handleInviteSubmit}
                >
                  <div className="flex flex-col items-center">
                    <label className="sr-only" htmlFor="cod_invito">
                      Codice Invito:
                    </label>
                    <input
                      type="textarea"
                      id="cod_invito"
                      value={cod_invito}
                      placeholder="Codice Invito"
                      onChange={(e) => setCodInvito(e.target.value)}
                      onBlur={handleCodiceInvitoBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:text-sm",
                        {
                          error: codInvitoError,
                          shake: codInvitoError,
                        }
                      )}
                      required
                    />
                    {codInvitoError && (
                      <p className="pldWarning">{codInvitoError}</p>
                    )}
                  </div>
                  <div className="flex flex-col items-center">
                    <label className="sr-only" htmlFor="id_tessera">
                      ID tessera:
                    </label>
                    <input
                      type="textarea"
                      id="id_tessera"
                      value={id_tessera}
                      placeholder="Id Tessera"
                      onChange={(e) => setid_tessera(e.target.value)}
                      onBlur={handleId_tesseraBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:text-sm",
                        {
                          error: id_tesseraError,
                          shake: id_tesseraError,
                        }
                      )}
                      required
                    />
                    {id_tesseraError && (
                      <p className="pldWarning">{id_tesseraError}</p>
                    )}
                  </div>
                  <div className="flex flex-col items-center">
                    <label className="sr-only" htmlFor="codice_sede">
                      Codice Sede:
                    </label>
                    <select
                      placeholder="Seleziona la Sede regionale Adiconsum"
                      onChange={(e) => setcodice_sede(e.target.value)}
                      onBlur={handlecodice_sedeBlur}
                      style={styleCodiceSede}
                      defaultValue=""
                      value={codice_sede}
                      className={classnames(
                        "pldInput block mt-1 w-full rounded-md sm:textarea-sm",
                        {
                          error: codice_sedeError,
                          shake: codice_sedeError,
                        }
                      )}
                      required
                    >
                      <option value={""} key={""} disabled>
                        Sede Regionale Adiconsum
                      </option>

                      <option value="sede1">sede1</option>
                      <option value="sede2">sede2</option>
                      <option value="sede3">sede3</option>
                    </select>
                    {codice_sedeError && (
                      <p className="pldWarning">{codice_sedeError}</p>
                    )}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <button
                      className="pldButton w-full"
                      type="submit"
                      onClick={handleInviteSubmit}
                    >
                      Invia
                    </button>
                    {inviteError && <p className="pldWarning">{inviteError}</p>}
                  </div>
                </form>

                <br></br>
                <div className="flex justify-center">
                  <div>Utente già registrato?</div>
                  <span className="pldLink" onClick={handleLogin}>
                    Accedi.
                  </span>
                </div>
              </>
            ) : null}
            {section === "PersonalForm" ? (
              <>
                <form
                  className="px-4 py-6 flex flex-col lg:grid gap-6 grid-cols-2"
                  onSubmit={handlePersonalSubmit}
                  autoComplete="off"
                >
                  <div>
                    <label className="sr-only" htmlFor="nome">
                      Nome
                    </label>
                    <input
                      type="textarea"
                      id="nome"
                      value={nome}
                      placeholder="Nome"
                      onChange={(e) => setNomeValue(e.target.value)}
                      onBlur={handleNomeBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                        {
                          error: nomeError,
                          shake: nomeError,
                        }
                      )}
                      required
                    />
                    {nomeError && <p className="pldWarning">{nomeError}</p>}
                  </div>
                  <div>
                    <label className="sr-only" htmlFor="cognome">
                      Cognome
                    </label>
                    <input
                      type="textarea"
                      id="cognome"
                      value={cognome}
                      placeholder="Cognome"
                      onChange={(e) => setCognomeValue(e.target.value)}
                      onBlur={handleCognomeBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                        {
                          error: cognomeError,
                          shake: cognomeError,
                        }
                      )}
                      required
                    />
                    {cognomeError && (
                      <p className="pldWarning">{cognomeError}</p>
                    )}
                  </div>
                  <div>
                    <label className="sr-only" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="textarea"
                      id="email"
                      value={email}
                      placeholder="Email"
                      onChange={(e) => setEmailValue(e.target.value)}
                      onBlur={handleEmailBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                        {
                          error: emailError,
                          shake: emailError,
                        }
                      )}
                      required
                    />
                    {emailError && <p className="pldWarning">{emailError}</p>}
                  </div>
                  <div>
                    <label className="sr-only" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="textarea"
                      id="cEmail"
                      placeholder="Conferma Email"
                      onChange={(e) => setCEmailValue(e.target.value)}
                      onBlur={handleCEmailBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                        {
                          error: cEmailError,
                          shake: cEmailError,
                        }
                      )}
                      required
                    />
                    {cEmailError && <p className="pldWarning">{cEmailError}</p>}
                  </div>
                  <div>
                    <label className="sr-only" htmlFor="password">
                      Password
                    </label>
                    <div>
                      <div className="flex flex-row relative">
                        <input
                          type="password"
                          autoComplete="off"
                          id="password"
                          value={password}
                          placeholder="Password"
                          onChange={(e) => setPasswordValue(e.target.value)}
                          onBlur={handlePasswordBlur}
                          className={classnames(
                            "pldInput block mt-1 rounded-md w-full pr-12 sm:textarea-sm z-[99]",
                            {
                              error: passwordError,
                              shake: passwordError,
                            }
                          )}
                        />
                        <button
                          className="w-[15%] flex justify-center items-center absolute right-0 bottom-3 z-[999]"
                          onClick={showPwd}
                          value="Mostra/nascondi password"
                        >
                          {imgPass ? (
                            <img
                              alt="Mostra"
                              src={eyeSolid}
                              style={{ width: "15px", opacity: "0.5" }}
                            />
                          ) : (
                            <img
                              alt="Nascondi"
                              src={eyeSlash}
                              style={{ width: "17px", opacity: "0.5" }}
                            />
                          )}
                        </button>
                      </div>
                      {!passwordError && (
                        <p style={{ fontSize: "small" }}>
                          Inserire almeno 8 caratteri tra cui: una lettera
                          maiuscola, una minuscola, un numero e un carattere
                          speciale.
                        </p>
                      )}
                      {passwordError && (
                        <p className="pldWarning">{passwordError}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-row relative">
                      <label className="sr-only" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="cPassword"
                        placeholder="Conferma Password"
                        onChange={(e) => setCPasswordValue(e.target.value)}
                        onBlur={handleCPasswordBlur}
                        className={classnames(
                          "pldInput block mt-1 rounded-md w-full pr-12 sm:textarea-sm z-[99]",
                          {
                            error: cPasswordError,
                            shake: cPasswordError,
                          }
                        )}
                        required
                      />
                      <button
                        className="w-[15%] flex justify-center items-center absolute right-0 bottom-3 z-[999]"
                        onClick={showCPwd}
                        value="Mostra/nascondi password"
                      >
                        {imgCPass ? (
                          <img
                            alt="Mostra"
                            src={eyeSolid}
                            style={{ width: "15px", opacity: "0.5" }}
                          />
                        ) : (
                          <img
                            alt="Nascondi"
                            src={eyeSlash}
                            style={{ width: "17px", opacity: "0.5" }}
                          />
                        )}
                      </button>
                    </div>
                    {cPasswordError && (
                      <p className="pldWarning">{cPasswordError}</p>
                    )}
                  </div>
                  <div>
                    <div className="flex flex-row grid grid-cols-8">
                      <label
                        className={classnames(
                          "flex items-center block col-start-1 col-end-5 text-[19px] pl-4",
                          {
                            shake: dataNascitaError,
                            pldWarning: dataNascitaError,
                          }
                        )}
                        htmlFor="data_nascita"
                      >
                        Data di Nascita:
                      </label>
                      <input
                        type="date"
                        id="data_nascita"
                        value={data_nascita}
                        placeholder="Data di Nascita"
                        onChange={(e) => setDataNascitaValue(e.target.value)}
                        onBlur={handleDataNascitaBlur}
                        style={styleDate}
                        className={classnames(
                          "pldInput block mt-1 rounded-md w-full col-start-5 col-end-9 sm:textarea-sm",
                          {
                            error: dataNascitaError,
                            shake: dataNascitaError,
                          }
                        )}
                        required
                      />
                    </div>
                    {dataNascitaError && (
                      <p className="pldWarning text-right">
                        {dataNascitaError}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="sr-only" htmlFor="codice_fiscale">
                      Codice Fiscale
                    </label>
                    <input
                      type="textarea"
                      id="codice_fiscale"
                      value={codice_fiscale}
                      placeholder="Codice Fiscale"
                      onChange={(e) => setCodiceFiscaleValue(e.target.value)}
                      onBlur={handleCodiceFiscaleBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                        {
                          error: codiceFiscaleError,
                          shake: codiceFiscaleError,
                        }
                      )}
                      required
                    />
                    {codiceFiscaleError && (
                      <p className="pldWarning">{codiceFiscaleError}</p>
                    )}
                  </div>
                  <div>
                    <label className="sr-only" htmlFor="telefono">
                      Telefono
                    </label>
                    <input
                      type="textarea"
                      id="telefono"
                      value={telefono}
                      placeholder="Numero di Telefono"
                      onChange={(e) => setTelefonoValue(e.target.value)}
                      onFocus={handleTelFocus}
                      onBlur={handleTelefonoBlur}
                      className={classnames(
                        "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                        {
                          error: telefonoError,
                          shake: telefonoError,
                        }
                      )}
                      required
                    />
                    {telefonoError && (
                      <p className="pldWarning">{telefonoError}</p>
                    )}
                  </div>
                  <div className="flex flex-row">
                    <div>
                      <label className="sr-only" htmlFor="provincia">
                        Provincia
                      </label>
                      <input
                        type="textarea"
                        autoComplete="off"
                        id="provincia"
                        value={provincia}
                        list="siglaProvincia"
                        placeholder="Provincia"
                        onChange={(e) => setProvinciaValue(e.target.value)}
                        onBlur={handleProvinciaBlur}
                        maxLength="2"
                        className={classnames(
                          "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                          {
                            error: provinciaError,
                            shake: provinciaError,
                          }
                        )}
                        required
                      />
                      <datalist id="siglaProvincia">
                        {sigla.map((provincia) => (
                          <option value={provincia} key={provincia} />
                        ))}
                      </datalist>{" "}
                      {provinciaError && (
                        <p className="pldWarning">{provinciaError}</p>
                      )}
                    </div>{" "}
                    <div className="flwx flex-col w-[80%]">
                      <label className="sr-only" htmlFor="citta">
                        Città
                      </label>
                      <input
                        type="textarea"
                        id="citta"
                        value={citta}
                        list="nomeCitta"
                        placeholder="Città"
                        onChange={(e) => setCittaValue(e.target.value)}
                        onBlur={handleCittaBlur}
                        disabled={dis}
                        className={classnames(
                          "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                          {
                            error: cittaError,
                            shake: cittaError,
                          }
                        )}
                        required
                      />
                      <datalist id="nomeCitta">
                        {comuniArrayState.map((citta) => (
                          <option value={citta} key={citta} />
                        ))}
                      </datalist>
                      {!provincia && (
                        <p style={{ fontSize: "small" }}>
                          Inserire prima la provincia!
                        </p>
                      )}
                      {cittaError && <p className="pldWarning">{cittaError}</p>}{" "}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    {" "}
                    <div className="flex flex-col w-[80%]">
                      <label className="sr-only" htmlFor="indirizzo">
                        Indirizzo
                      </label>

                      <input
                        type="textarea"
                        id="indirizzo"
                        value={indirizzo}
                        placeholder="Indirizzo"
                        onChange={(e) => setIndirizzoValue(e.target.value)}
                        onBlur={handleIndirizzoBlur}
                        className={classnames(
                          "pldInput block mt-1 rounded-md sm:textarea-sm",
                          {
                            error: indirizzoError,
                            shake: indirizzoError,
                          }
                        )}
                        required
                      />
                      {indirizzoError && (
                        <p className="pldWarning">{indirizzoError}</p>
                      )}
                    </div>
                    <div className="flex flex-col w-[20%]">
                      <input
                        type="textarea"
                        id="civico"
                        value={civico}
                        placeholder="Civico"
                        onChange={(e) => setCivicoValue(e.target.value)}
                        onBlur={handleCivicoBlur}
                        className={classnames(
                          "pldInput block mt-1 rounded-md  sm:textarea-sm",
                          {
                            error: civicoError,
                            shake: civicoError,
                          }
                        )}
                        required
                      />
                      {civicoError && (
                        <p className="pldWarning">{civicoError}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row grid grid-cols-6">
                    <div className="col-start-1 col-end-6 ">
                      <label className="sr-only" htmlFor="tipo_indirizzo">
                        Tipo di Indirizzo
                      </label>
                      <select
                        placeholder="Seleziona il tipo di indirizzo"
                        onChange={(e) => setTipoIndirizzoValue(e.target.value)}
                        onBlur={handleTipoIndirizzoBlur}
                        style={styleTipo}
                        defaultValue=""
                        value={tipo_indirizzo}
                        className={classnames(
                          "pldInput block mt-1 w-full rounded-md sm:textarea-sm",
                          {
                            error: tipoIndirizzoError,
                            shake: tipoIndirizzoError,
                          }
                        )}
                        required
                      >
                        <option value={""} key={""} disabled>
                          Tipo di Indirizzo
                        </option>
                        {tipiIndirizzo.map((tipo) => (
                          <option value={tipo} key={tipo}>
                            {tipo}
                          </option>
                        ))}
                      </select>
                      {tipoIndirizzoError && (
                        <p className="pldWarning">{tipoIndirizzoError}</p>
                      )}{" "}
                    </div>
                    <div className="col-start-6 col-end-6 ">
                      <label className="sr-only" htmlFor="cap">
                        Cap
                      </label>
                      <input
                        type="textarea"
                        id="capo"
                        value={cap}
                        placeholder="CAP"
                        onChange={(e) => setCapValue(e.target.value)}
                        onBlur={handleCapBlur}
                        className={classnames(
                          "pldInput block mt-1 rounded-md w-full sm:textarea-sm",
                          {
                            error: capError,
                            shake: capError,
                          }
                        )}
                        required
                      />
                      {capError && <p className="pldWarning">{capError}</p>}
                    </div>{" "}
                  </div>
                  <button className="pldButton" onClick={handleInviteNavigate}>
                    Indietro
                  </button>
                  <button
                    className="pldButton"
                    type="submit"
                    onClick={handlePersonalSubmit}
                  >
                    Avanti
                  </button>{" "}
                </form>
                {personalError && (
                  <p className="pldWarning text-center">{personalError}</p>
                )}
              </>
            ) : null}
            {section === "SettingForm" ? (
              <>
                {modal ? (
                  <>
                    {" "}
                    <Modal
                      show={true}
                      size="md"
                      popup={true}
                      onClose={handleModal}
                      className="pt-[25%] lg:pt-0"
                    >
                      <Modal.Header />
                      <Modal.Body>
                        <div className="text-center flex justify-center flex-col">
                          {modalItems[0] === "error" ? (
                            <img
                              className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
                              src={circleExclamation}
                              alt="Attention"
                            />
                          ) : null}
                          {modalItems[0] === "privacy" ? (
                            <img
                              className="mx-auto mb-4 h-20 w-20 text-gray-400 dark:text-gray-200"
                              src={Privacy}
                              alt="Privacy"
                            />
                          ) : null}
                          {modalItems[0] === "check" ? (
                            <img
                              className="mx-auto mb-4 h-20 w-20 text-gray-400 dark:text-gray-200"
                              src={circleCheck}
                              alt="Privacy"
                            />
                          ) : null}
                          {modalItems[0] === "loading" ? (
                            <div className="flex justify-center items-center">
                              <div className="loading-spinner "></div>
                            </div>
                          ) : null}
                          <div
                            style={{ maxHeight: "400px", overflowY: "auto" }}
                          >
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                              {textModal}
                            </h3>
                          </div>
                          <div className="pt-4">
                            {!loading ? (
                              <button
                                className="pldButton flex justify-center gap-4 w-full"
                                onClick={handleClickM}
                              >
                                {modalItems[2]}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                ) : null}
                <form className="pt-4 pr-4 pl-1 grid gap-6 grid-cols-5 justify-between pr-0">
                  <div className="col-start-1 col-end-7">
                    {" "}
                    <label htmlFor="privacy">
                      Acconsento al trattamento dei dati dopo aver preso visione
                      dell'
                    </label>
                    <span className="pldLink" onClick={handlePrivacyPolicies}>
                      Informativa sulla privacy
                    </span>
                    *
                  </div>
                  <div className="col-start-7 flex justify-center items-center">
                    <input
                      id="privacy"
                      type="checkbox"
                      checked={privacy}
                      value={privacy}
                      onChange={handlePrivacyCheck}
                      required
                      onBlur={handlePrivacyBlur}
                      className={classnames("", {
                        error: privacyError,
                      })}
                    />
                  </div>
                  <div className="col-start-1 col-end-7">
                    <label htmlFor="Newsletter">
                      Acconsento all'iscrizione alla newsletter di Paladin per
                      restare sempre aggiornato/a
                    </label>
                  </div>
                  <div className="col-start-7 flex justify-center items-center">
                    <input
                      id="newsletter"
                      type="checkbox"
                      checked={newsletter}
                      value={newsletter}
                      onChange={handleNewsCheck}
                    />
                  </div>{" "}
                  <button
                    className="pldButton col-start-1 col-end-4"
                    onClick={handlePersonalNavigate}
                  >
                    Indietro
                  </button>
                  <button
                    className="pldButton col-start-4 col-end-8"
                    type="submit"
                    onClick={handleSettingSubmit}
                  >
                    Avanti
                  </button>
                </form>
              </>
            ) : null}
            {section === "Finale" ? (
              <>
                {modal ? (
                  <>
                    {" "}
                    <Modal
                      show={true}
                      size="md"
                      popup={true}
                      onClose={handleModal}
                      className="pt-[25%] lg:pt-0"
                    >
                      <Modal.Header />
                      <Modal.Body>
                        <div className="text-center flex justify-center flex-col">
                          {modalItems[0] === "error" ? (
                            <img
                              className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
                              src={circleExclamation}
                              alt="Attention"
                            />
                          ) : null}
                          {modalItems[0] === "privacy" ? (
                            <img
                              className="mx-auto mb-4 h-20 w-20 text-gray-400 dark:text-gray-200"
                              src={Privacy}
                              alt="Privacy"
                            />
                          ) : null}
                          {modalItems[0] === "check" ? (
                            <img
                              className="mx-auto mb-4 h-20 w-20 text-gray-400 dark:text-gray-200"
                              src={circleCheck}
                              alt="Privacy"
                            />
                          ) : null}
                          {modalItems[0] === "loading" ? (
                            <div className="flex justify-center items-center">
                              <div className="loading-spinner "></div>
                            </div>
                          ) : null}
                          <div
                            style={{ maxHeight: "400px", overflowY: "auto" }}
                          >
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                              {textModal}
                            </h3>
                          </div>
                          <div className="pt-4">
                            {!loading ? (
                              <button
                                className="pldButton flex justify-center gap-4 w-full"
                                onClick={handleClickM}
                              >
                                {modalItems[2]}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                ) : null}
                <div className="px-4 py-6 lg:grid gap-6 grid-cols-2">
                  <p>Codice Invito: {inputData.cod_invito}</p>
                  <p>Id Tessera: {inputData.id_tessera}</p>
                  <button
                    className="pldButton col-start-1 col-end-3"
                    onClick={handleInviteNavigate}
                  >
                    Modifica
                  </button>
                  <hr className="dividerProfile col-start-1 col-end-3"></hr>
                  <p>Codice Sede: {inputData.codice_sede}</p>
                  <p>Nome: {inputData.nome}</p>
                  <p>Cognome: {inputData.cognome}</p>
                  <p>Email: {inputData.email}</p>
                  <p>Password: {maskPass(inputData.password)}</p>
                  <p>Data di nascita: {inputData.data_nascita}</p>
                  <p>Codice Fiscale: {inputData.codice_fiscale}</p>
                  <p>Numero di telefono: {inputData.telefono}</p>
                  <p>Città: {inputData.citta}</p>
                  <p>Provincia: {inputData.provincia}</p>
                  <p>Indirizzo: {inputData.indirizzo}</p>
                  <p>Civico: {inputData.civico}</p>
                  <p>Tipo di indirizzo: {inputData.tipo_indirizzo}</p>
                  <p>Cap: {inputData.cap}</p>
                  <button
                    className="pldButton col-start-1 col-end-3"
                    onClick={handlePersonalNavigate}
                  >
                    Modifica
                  </button>
                  <hr className="dividerProfile col-start-1 col-end-3"></hr>
                  <p>privacy: {privacy ? "vero" : "falso"}</p>
                  <p>Newsletter: {newsletter ? "vero" : "falso"}</p>
                  <button
                    className="pldButton col-start-1 col-end-3"
                    onClick={handleSettingNavigate}
                  >
                    Modifica
                  </button>
                  <hr className="dividerProfile col-start-1 col-end-3"></hr>
                  <button
                    className="pldButton col-start-1 col-end-3"
                    onClick={handleSubmit4}
                  >
                    Conferma!
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default Registration;

