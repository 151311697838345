import { useState,useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from "react-bootstrap/Table";

import trueicon from "../IMG/true.png";
import falseicon from "../IMG/false.png";

export default function SchoolModal(props) 
{

    const [show, setShow] = useState(false);
    const [scuola, setScuola] = useState({comune:"",codicescuola:"",indirizzo:"",auditorium:"",aule_informatica:"",aule_tecniche:"",palestra:"",piscina:"",mensa:""});

    const handleClose = () => setShow(false);
    const handleShow = () => 
    {
      getDataSchool(props.nomescuola+" "+props.via,props.comune); 
      setShow(true);
    }
    const getDataSchool = (scuolaevia,comune) => {

    
      axios(`https://paladin.expleoitalia.it/service/api/scuola`,   {
      method: 'post', headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + props.keycloak.token },data:{"search":scuolaevia,"comune":comune},
    }, { timeout: 2000 }).then
        (response => {


          console.log("Scuolaaaaaaaaaaaaaaa");
          console.log(response.data[0]);
          if(response.data[0]) setScuola(response.data[0]);
         // console.log("Scuola");
        //  console.log(scuola);
        }
        );
      }
   // console.log(props.dettaglio);
   //console.log("codice scuola  ver"+scuola.codicescuola);
   //if(scuola.codicescuola!="")
   {
    //console.log("codice scuola "+scuola.codicescuola);
    return (
    <>
      <Button onClick={handleShow}>
        Dettagli
      </Button>

{/* //////////////////////////////////// */}

<Modal show={show} onHide={handleClose} scrollable className="fixed bottom-1/2 left-1/2  group z-[100000]">
                <Modal.Header closeButton>
                    <Modal.Title> Scuola </Modal.Title>

                </Modal.Header>
                <Modal.Body scroll>
                    <div className="h-1/3" scroll>
                      
                            <Table striped bordered hover size="sm"  >
                                {/* <thead>
                                    <tr>
                                        
                                        <th>Col1</th>
                                        <th>Col2</th>
                                     
                                    </tr>
                                </thead> */}
                                <tbody>
                                   

{/* <tr> <td> Comune </td> <td>{scuola.comune}</td>  </tr> */}
<tr> <td> Codice Scuola </td> <td>{scuola.codicescuola}</td>  </tr>
<tr> <td> Indirizzo </td> <td>{scuola.indirizzo}</td>  </tr>
<tr> <td> Auditorium </td> <td>
  
  { 
   (scuola.auditorium>0) ? (<><img  width='20' src={trueicon} ></img></>):(<><img  width='20' src={falseicon} ></img></>) 
  }
  
  </td>  </tr>

<tr> <td> Aule Informatica </td> 
<td>
  
  {
  (scuola.aule_informatica>0) ? (<><img  width='20' src={trueicon} ></img></>):(<><img  width='20' src={falseicon} ></img></>) 
  } 


</td>  </tr>
<tr> <td> Aule Tecniche </td> <td>
  {
  (scuola.aule_tecniche>0) ? (<><img  width='20' src={trueicon} ></img></>):(<><img  width='20' src={falseicon} ></img></>) 
  }</td>  </tr>
<tr> <td> Palestra </td> <td>
  {
  (scuola.palestra>0) ? (<><img  width='20' src={trueicon} ></img></>):(<><img  width='20' src={falseicon} ></img></>) 
  }
  </td>  </tr>
<tr> <td> Piscina </td> <td>
  {
  (scuola.piscina>0) ? (<><img  width='20' src={trueicon} ></img></>):(<><img  width='20' src={falseicon} ></img></>) 
  }
  </td>  </tr>
<tr> <td> Mensa </td> <td>
  {
  (scuola.mensa>0) ? (<><img  width='20' src={trueicon} ></img></>):(<><img  width='20' src={falseicon} ></img></>) 
  }
  </td>  </tr>
                                   
                                </tbody>
                            </Table>
                      </div>


                {/* <table class="table" >
                        <thead class=" text-gray-700  bg-green-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-5"> Codice   </th>
                                <th scope="col" class="px-6 py-5"> Descrizione  </th>
                                <th scope="col" class="px-6 py-5"> Tot.  </th>
                            </tr>`
                        </thead>
                        <tbody>          {props.spData.map((dato) => (
                              <tr>
                                <th scope="row">{dato.codice_missione_arconet}</th>
                                <td>{dato.desc_missione_arconet}</td>
                                <td>{dato.tot_pre_cassa_ep}</td>
                                    </tr>
                        ))}

                        </tbody>

                    </table> */}



            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Chiudi
                </Button>

            </Modal.Footer>
        </Modal >

{/* //////////////////////////////////// */}

      {/* <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title> Dettagli {props.nomescuola} - {props.via}</Modal.Title>

        </Modal.Header>
        <Modal.Body >

      
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
           <tr> <td> Comune </td> <td>{scuola.comune}</td>  </tr>
           <tr> <td> Codice Scuola </td> <td>{scuola.codicescuola}</td>  </tr>
           <tr> <td> Indirizzo </td> <td>{scuola.indirizzo}</td>  </tr>
           <tr> <td> Auditorium </td> <td>{scuola.auditorium}</td>  </tr>
          
           <tr> <td> Aule Informatica </td> <td>{scuola.aule_informatica}</td>  </tr>
           <tr> <td> Aule Tecniche </td> <td>{scuola.aule_tecniche}</td>  </tr>
           <tr> <td> Palestra </td> <td>{scuola.palestra}</td>  </tr>
           <tr> <td> Piscina </td> <td>{scuola.piscina}</td>  </tr>
           <tr> <td> Mensa </td> <td>{scuola.mensa}</td>  </tr>
           
           
          </table>
         
       

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Chiudi
          </Button>
        
        </Modal.Footer>
      </Modal> */}
    </>
  )} }
