
import logo from "../IMG/paladinlogohome.png";
import desktop from "../IMG/home.jpg";
import { useNavigate } from "react-router-dom";


const HomePage = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    navigate("/forms");
  };
  return (
    <>
     
      <div className="w-full h-screen flex  p-1 bg-[#ffffff] py-0 pl-0">
      
        { <div className="w-2/5 h-[35rem] flex items-center flex-wrap justify-center pl-0">
          <div className="w-4/5 h-full flex bg-white rounded-r-lg flex-col items-center flex-wrap gap-3 justify-center px-1">
            <h1 className="w-full text-3xl flex-wrap">
            <img className="w-[70%]" src={logo} alt="paladin" />
            </h1>
            <p className="w-full text-3xl  text-green-700 flex flex-wrap">
              <b>ADICONSUM</b>
            </p>
            <p>
              Un’ APP intuitiva e funzionale dedicata ai lavoratori Adiconsum.
              <br></br>
              
              Naviga i punti di interesse, ricevi le notifiche sugli eventi, e
              molto altro ancora
            </p>
            <div className="w-full p-20 flex flex-row gap-1 items-center justify-center">
              <button
                onClick={handleLogin}
                className="w-2/5 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-600 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
              >
                Login
              </button>
              <button
                onClick={handleRegister}
                className="w-2/5 flex flex-wrap justify-center px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-800 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
              >
                Registrati
              </button>
            </div>
          </div>
        </div> }

        { <div className="w-3/5 h-[45rem] flex items-center flex-wrap justify-center">
       
          <img className="w-[100%]" src={desktop}  />
          {/* <img className="w-[60%] absolute " src={mobile} alt="" /> */}
        </div> }
      </div>
    </>
  );
};

export default HomePage;
