import { BrowserRouter } from 'react-router-dom'

import { Paladin } from '../Paladin'

export function Root() {
  return (
    <BrowserRouter>
      <Paladin />
    </BrowserRouter>
  )
}
