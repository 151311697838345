import React from "react";
import { CButton } from "@coreui/react";
import { forwardRef, useRef } from 'react';
import gpsicon from "../IMG/gps2.png";
import gpsicon2 from "../IMG/gps.png";


// const MyInput = forwardRef((props, ref) => {
//     return <input {...props} ref={ref} />;
//   });


export default function SearchPOI(props) {

   const inputRef = useRef(null);
   

  
   const handleClick = () => {
    if (inputRef.current.value !== null) {
              //  alert("Input is :"+ inputRef.current.value);
                props.setSearch(inputRef.current.value);
     // console.log(inputRef.current.value);
    }
  };
  
  const gpsClick = () => {
                props.setGpsState(!props.gpsState);
                //props.setSearch(inputRef.current.value);
     
    
  };
  const getRange= (e) => {
    //console.log(e);
    //props.setGpsState(!props.gpsState);
    //props.setSearch(inputRef.current.value);


};
    if(props.gpsState)
    {
    return (
        <div className="  group z-[100] ">
            <div className="flex space-x-1">
                <input
                    type="text" ref={inputRef}
                    className="block w-full px-4 py-2 text-green-700 bg-white border rounded focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                <button className="px-4 text-white bg-green-600 rounded-full "  onClick={handleClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                </button>
                <button  className="px-4 text-white bg-green-600 rounded-full" onClick={() => props.setVisible(true)}>Dettagli </button>
                <button  className="px-4 text-white " onClick= {gpsClick}><img src={gpsicon} width='70'></img> </button>
               
                {/* <input type="range" class="w-full" min="1" max="6" step="1" onChange= {getRange} /> */}


              
            </div>
        </div>
    ); }
    else 
    {
        return (
            <div className="  group z-[10000] ">
                <div className="flex space-x-1">
                    <input
                        type="text" ref={inputRef}
                        className="block w-full px-4 py-2 text-green-700 bg-white border rounded focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <button className="px-4 text-white bg-green-600 rounded-full "  onClick={handleClick}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                    </button>
                    <button  className="px-4 text-white bg-green-600 rounded-full" onClick={() => props.setVisible(true)}>Dettagli </button>
                    <button  className="px-4 text-white " onClick= {gpsClick}><img src={gpsicon2} width='70'></img> </button>
                  
                </div>
            </div>
        );
    }
}