import { Disclosure, Menu } from '@headlessui/react'
import { Home, Bell,MenuAlt4 } from 'heroicons-react'

import React from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faMagic,  faNewspaper } from "@fortawesome/free-solid-svg-icons";
import faGavel from "../IMG/gavel-solid.svg";
import faCalendar from "../IMG/calendar.svg";
import axios from "axios";
import XMLParser from 'react-xml-parser';
import Button from 'react-bootstrap/Button';
import logo from "../IMG/logo.svg";
import { useState } from "react";
import News from '../Pages/News';
import UserProfile from './UserProfile';
import SearchPOI from './SearchPOI';
import LocalInformation from './LocalInformation';
import LocalInformationE from './LocalInformationE';
import BandaLargaInformation from './BandaLargaInformation';
import gpsicon from "../IMG/gps.png";
import Modal from 'react-bootstrap/Modal';

export default function TopBar(props)
{
 
  const navigate = useNavigate();

 
  var [showModal, setShowModal] = useState(false);

  const handleClick = () => {
   
   // setShowModal(true);
   // alert("da topbar"+props.token);
   

   navigate(`/news/${props.token}`)
   
  };
  const handleClickEvents = () => {
   
    setShowModal(true);
    navigate(`/events/${props.token}`);
   
  };

  const handleClose = () => {
    setShowModal(false);
   
  };

  var [showModal2, setShowModal2] = useState(false);

  const handleClick2 = () => {
    setShowModal2(true);
   
  };

  const handleClose2 = () => {
    setShowModal2(false);
   
  };

  var [showModal3, setShowModal3] = useState(false);

  const handleClick3 = () => {
    setShowModal3(true);
   
  };

  const handleClose3 = () => {
    setShowModal3(false);
   
  };



  const [visibilityState,setVisibility] = useState("Hidden");
  

  const { keycloak } = props;


  const setSearch = (search) => {
   props.setSearch(search);
  }; 

  const logout = () => {
    keycloak.logout({
      redirectUri: "https://paladin.expleoitalia.it",
    });
  }; 






  // alert("userid"+keycloak.tokenParsed.sub);
   // console.log(keycloak);

    const user = {
        name: '',
      //  email: keycloak.tokenParsed.email,
        imageUrl:
          './IMG/user-solid.svg',
      }
      
      // Viene ricavata la lista di Navigazione della TOP BAR
      const navigation = []

      let myNodeList=[];


      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

      
   axios.get('filter.xml', {
    "Content-Type": "application/xml; charset=utf-8"
   } ).then(res => 
    {
     let data = new XMLParser().parseFromString(res.data);
     myNodeList   = data.getElementsByTagName('link');
     
    for (let i=0; i < data.getElementsByTagName('link').length ; i++) 
    {
    //  navigation.push({ name:myNodeList[i].getElementsByTagName("name")[0].value, href: myNodeList[i].getElementsByTagName("route")[0].value, current: false });
      
   
    //  filtro.push({ name:myFilterList[i].getElementsByTagName("name")[0].value, href: myFilterList[i].getElementsByTagName("route")[0].value });
    } 
    });


  return(<>
  
  <div>     
        <Disclosure as="nav"  >
        {({ open }) => (
            <>
              <div className="mx-auto  selection:max-w-7xl px-8 sm:px-2 lg:px-8">
                <div className="flex h-26 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-24 "
                        src={logo} 
                        alt="PALADIN"
                      />
                    </div>
                    <div>
                    <SearchPOI position={props.position}  setVisible={props.setVisible} gpsState={props.gpsState} setGpsState={props.setGpsState} setSearch={setSearch} />
                    </div>
                    <div className="flex h-26 items-center justify-between" >
                   
                   <LocalInformation setComune={props.setComune}  setProcom={props.setProcom} setComunePolygon={props.setComunePolygon} setSigla={props.setSigla}  keycloak={keycloak}  position={props.position}/> 
                   <BandaLargaInformation  setBandaPolygon={props.setBandaPolygon} setMarkerPolygon={props.setMarkerPolygon}  keycloak={keycloak}  position={props.position}/> 
                 
                    </div>
                    <div className="bg-white hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-2">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-green-600 hover:bg-green-700 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                         {/* <div><button
                        type="button"
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        < FontAwesomeIcon icon={faWifi}  />
                      </button></div>
                      <div><button
                        type="button"
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <FontAwesomeIcon icon={faMoneyBill}  />
                      </button></div>
                      <div><button
                        type="button"
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <FontAwesomeIcon icon={faPeopleGroup}  />
                      </button></div>
                      <div><buttond
                        type="button"
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <FontAwesomeIcon icon={faSmile}  />
                      </buttond></div>*/}
                      </div> 
                      <div>
                        
                      </div>
                     
                    </div>
                   
                      
                    
                  </div>
            


                  <div className="hidden md:block z-[100]">
                    <div className="ml-4 flex items-center md:ml-6 ">
                    
                    <div className="p-2">
                      <button
                        type="button" onClick={handleClick2}
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        
                        <img  src={faGavel} className="h-6 w-6" aria-hidden="true" />
                      </button>
                      <Modal show={showModal2} onHide={handleClose2} scrollable className="fixed bottom-1/2 left-1/2  group z-[100000]">
                          <Modal.Header closeButton>
                            <Modal.Title> Sentenze {showModal}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body scroll>Lo Strumento consente al consumatore di effettuare una interrogazione allo stesso, di seguito nominata anche “richiesta” attraverso la descrizione testuale del quesito o il caricamento di documenti esplicativi la questione sottoposta.<br></br>
                           La richiesta viene istantaneamente elaborata in termini giuridici, e l’utente riceverà come output una o più indicazioni su come procedere al meglio, in base alle informazioni da esso riportate.</Modal.Body>
                          <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose2}>
                            Chiudi
                        </Button>

            </Modal.Footer>
                        </Modal>
                      
                      </div>
                      <div className="p-2">
                      <button
                        type="button" 
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        onClick={handleClick} > <span className="sr-only">View notifications</span>
                        
                        <FontAwesomeIcon icon={faNewspaper} className="h-6 w-6" aria-hidden="true" />
                    </button>
                    </div>
                    <div className="p-2">
                    <button
                        type="button" 
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        onClick={handleClickEvents} > <span className="sr-only">View notifications</span>
                        
                        <img  src={faCalendar} className="h-6 w-6" aria-hidden="true" />
                    </button>
                        
                        <Modal show={showModal} onHide={handleClose} scrollable className="fixed bottom-1/2 left-1/2  group z-[100000]">
                          <Modal.Header closeButton>
                            <Modal.Title> News {showModal}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body scroll>In questa sezione vengono mostrate le News<br></br></Modal.Body>
                          <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Chiudi
                        </Button>

            </Modal.Footer>
                        </Modal>
                         </div>

                         <div className="p-2">
                      <button
                        type="button"
                        className="rounded-full bg-green-800 p-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        onClick={handleClick3} > <span className="sr-only">View notifications</span>
                        
                        <FontAwesomeIcon icon={faBell} className="h-6 w-6" aria-hidden="true" />
                    </button>
                        
                        <Modal show={showModal3} onHide={handleClose3} scrollable className="fixed bottom-1/2 left-1/2  group z-[100000]">
                          <Modal.Header closeButton>
                            <Modal.Title> Notifiche {showModal3}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body scroll>In questa sezione vengono mostrate le Notifiche all'utente per la sua provincia di appartenenza, o le notifiche generali Adiconsum agli associati<br></br></Modal.Body>
                          <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose3}>
                            Chiudi
                        </Button>

            </Modal.Footer>
                        </Modal>
                         </div>  
                  
                       <div>{keycloak.tokenParsed.given_name}</div>
                     
                      <Menu as="div" className="relative ml-3">
                        
                     
                        
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                          </Menu.Button>
                        
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          
                        {/* <Menu.Item>
                            <a onClick={()=>setVisibility("Visible") } className=
                                       "bg-gray-100 block px-4 py-2 text-sm text-gray-700"
                                    >Profilo</a>
                                    
                          </Menu.Item> */}
                          <Menu.Item>
                          <a href="#" onClick={logout} className=
                                       "bg-gray-100 block px-4 py-2 text-sm text-gray-700"
                                    >Profilo</a>
                          </Menu.Item>

                         <Menu.Item>
                          <a href="#" onClick={logout} className=
                                       "bg-gray-100 block px-4 py-2 text-sm text-gray-700">
                                        Logout
                                      </a>
                          </Menu.Item>
                        
                        </Menu.Items>
                                               {/*
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a onClick={logout}
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </a >
                                )}
                                
                              </Menu.Item>
                            ))}
                          </Menu.Items>*/}
                        
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-green-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <MenuAlt4 className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuAlt4 className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="bg-white opacity-90 md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className=" border-green-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-black">{user.name}</div>
                      <div className="text-sm font-medium leading-none text-black">{user.email}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <Bell className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                 {/*    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      > 
                        {item.name}
                      </Disclosure.Button>
                    ))}*/}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* <div style={{visibility: visibilityState}} ><UserProfile  setVisibility={setVisibility}  keycloak={keycloak} /> </div>
        */}
         </div>
  
  </>);  
}

