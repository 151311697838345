import { useState,useEffect } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export default function OpereModal(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


   { 
   
   return (
    <>
      <Button variant="success" onClick={handleShow}>
        Lista Opere
      </Button>

      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Opere</Modal.Title>
        </Modal.Header>
        <Modal.Body >

        { props.opere.map((item) => (
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
           <tr> <td>{item.label}<a href={item.res} target="opera"> View</a></td> </tr>
          </table>
         
        ))}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Chiudi
          </Button>
        
        </Modal.Footer>
      </Modal>
    </>
  ) }
}