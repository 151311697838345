import { useState,useEffect } from "react";
import axios from "axios";
import L from "leaflet";



export default function LocalInformation(props) 
{
    
    const [comune, setComune] = useState("Non definito"); 
    const [procom,setProcom] =useState("Non definito procom");
    const [time,setTime]=useState(new Date().getTime());
    const [comunepolygon,setComunePolygon]=useState();
    const [elaborazione,setElaborazione]=useState(false);
    
    var oraInvocazione;
    
    const invocaConfini= () => {
      // console.log("Invocato Confini");
       oraInvocazione=new Date().getTime();
      if((oraInvocazione-time)>3000){
     //   console.log("oraInvocazione: " + oraInvocazione)
     //   console.log("time: " + time)
     //   console.log((oraInvocazione-time))
       
      }

      //console.log("eccoci qui")

       if((oraInvocazione-time)>1000)
       {
        if(!comunepolygon)
        {
        //   console.log("Invocato per ComunePolygon==null");
           getConfiniAmministrativi(props.position[0],props.position[1]);
           setTime(new Date().getTime());
        }
        else
        {
       
        console.log(comunepolygon);
        var polygon = L.polygon(
          comunepolygon[0].geometry.coordinates
        );
         var m1 = L.marker([props.position[0], props.position[1]]); // Outside and north of polygon
         if(!polygon.getBounds().contains(m1.getLatLng()))
         {  //console.log("Possiamo invocare setConfini"); 
           getConfiniAmministrativi(props.position[0],props.position[1]);
           setTime(oraInvocazione);
         }
        }   
       
      
      }

    }
    
    
    const getConfiniAmministrativi = (lat,lan) => {
        var elementspolygonlocal = [];
    
         axios(`https://paladin.expleoitalia.it/service/api/confini/comune/${lat}/${lan}`,   {
       // axios(`http://192.168.1.120:3000/api/confini/comune/lite/${lat}/${lan}`,   {
          method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
        }, { timeout: 2000 }).then
         (response => {
            setComune(response.data[0].denominazione);
            setProcom(response.data[0].proCom);
            props.setComune(response.data[0].denominazione);
            props.setProcom(response.data[0].proCom);
           // props.setSigla(response.data[0].sigla);
         //  console.log("Stampa dentro localinformation");
         //  console.log(response.data[0]);
           var polygonlocal=response.data;
           if(response.data[0].geometry.type=="MultiPolygon")
           {
     //        console.log("Oh mio caro multipoligon");
     //        console.log("lunghezza della geometry");
     //        console.log(response.data[0].geometry.coordinates.length);
     //        console.log(response.data[0].geometry.coordinates[0][0]);
            }

            if(response.data[0].geometry.type=="MultiPolygon")
            {
           for (let i = 0; i < polygonlocal[0].geometry.coordinates.length; i++) {
            for(let c=0;c<polygonlocal[0].geometry.coordinates[i].length;c++)
            {
              for(let d=0;d<polygonlocal[0].geometry.coordinates[i][c].length;d++)
            {
         //     console.log("coordinates["+i+"]["+c+"]["+d+"]");
              var x= polygonlocal[0].geometry.coordinates[i][c][d][0];
              var y= polygonlocal[0].geometry.coordinates[i][c][d][1];
              polygonlocal[0].geometry.coordinates[i][c][d][0]=y;
              polygonlocal[0].geometry.coordinates[i][c][d][1]=x;
        //      console.log("x "+x+"y "+y);  
            }
            }
            setComunePolygon(polygonlocal);
            props.setComunePolygon(polygonlocal);
          }
        }
        else
        {
          for(let c=0;c<polygonlocal[0].geometry.coordinates.length;c++)
          {
            for(let d=0;d<polygonlocal[0].geometry.coordinates[c].length;d++)
          {
          //  console.log("coordinates["+c+"]["+d+"]");
            var x= polygonlocal[0].geometry.coordinates[c][d][0];
            var y= polygonlocal[0].geometry.coordinates[c][d][1];
            polygonlocal[0].geometry.coordinates[c][d][0]=y;
            polygonlocal[0].geometry.coordinates[c][d][1]=x;
         //   console.log("x "+x+"y "+y);  
          }
          }
            setComunePolygon(polygonlocal);
            props.setComunePolygon(polygonlocal);
        }

      
             props.setComunePolygon(polygonlocal);
            
            //handlePolygonChange(elementspolygonlocal);

            }
          ).catch((err) => { console.log('fetch request failed: ', err) })
      
          axios(`https://paladin.expleoitalia.it/service/api/confini/provincia/${lat}/${lan}`,   {
            // axios(`http://192.168.1.120:3000/api/confini/comune/lite/${lat}/${lan}`,   {
               method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + props.keycloak.token },
             }, { timeout: 2000 }).then
              (response => {
     //            console.log("Stampiamo da local");
     //            console.log(response);  
                 props.setSigla(response.data[0].sigla);
                 



               }
               ).catch((err) => { console.log('fetch request failed: ', err) })
             

      }

     //Recuperiamo name e comune da confini amministrativi
    // con UseSTATE per lo stato di tags.name e 
    // console.log(props.position);
   { 
    
     if((!comunepolygon)&&(!elaborazione))
        {
           //console.log("Invocato per ComunePolygon==null");
           getConfiniAmministrativi(props.position[0],props.position[1]);
           setTime(new Date().getTime());
           setElaborazione(true);
         }
     invocaConfini();
    // console.log("Ricaricata Localinformation");
    
    
    
    
    return (
    <div className="p-3">
     <button  className="px-4 text-white bg-orange-400 rounded-full" >{comune} | {procom} </button>
    </div>           
     ) }
}