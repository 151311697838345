import { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BasicTable from "./BasicTable";
import Table from "react-bootstrap/Table";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function SpesaPubbicaModal(props) {

    const [show, setShow] = useState(false);
    const [key, setKey] = useState('rendiconto');

    const handleClose = () => {
        setShow(false);
        props.setSpVisible(false);
    }


    const handleShow = () => {

    }

    // alert(props.Spvisible);
    // if(props.visible) setShow(true);


    return (
        <>
            {/* <Button onClick={handleShow}>
        Dettagli
      </Button> */}

            <Modal show={props.spVisible} onHide={handleClose} scrollable className="fixed bottom-1/2 left-1/2  group z-[100000]">
                <Modal.Header closeButton>
                    <Modal.Title> Spesa Pubblica <br></br>{props.spDenominazione} <br></br> Anno : {props.spEsercizio}</Modal.Title>

                </Modal.Header>
                <Modal.Body scroll>
                    <div className="h-1/3" scroll>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            
                        <Tab eventKey="rendiconto" title="Rendiconto">
                            <Table striped bordered hover size="sm"  >
                                <thead>
                                    <tr>
                                      
                                        <th>Codice</th>
                                        <th>Descrizione</th>
                                        <th>Totale (Euro €)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.spRendicontoData.map((dato) => (

                                        <tr>
                                            
                                            <td>{dato.codice_missione_arconet}</td>
                                            <td>{dato.desc_missione_arconet}</td>
                                            <td>{dato.tot_pag_in_cc.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
 
                    </Tabs>
                </div>


                {/* <table class="table" >
                        <thead class=" text-gray-700  bg-green-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-5"> Codice   </th>
                                <th scope="col" class="px-6 py-5"> Descrizione  </th>
                                <th scope="col" class="px-6 py-5"> Tot.  </th>
                            </tr>`
                        </thead>
                        <tbody>          {props.spData.map((dato) => (
                              <tr>
                                <th scope="row">{dato.codice_missione_arconet}</th>
                                <td>{dato.desc_missione_arconet}</td>
                                <td>{dato.tot_pre_cassa_ep}</td>
                                    </tr>
                        ))}

                        </tbody>

                    </table> */}



            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Chiudi
                </Button>

            </Modal.Footer>
        </Modal >
        </>
    )
}
