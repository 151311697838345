import { createSlice } from "@reduxjs/toolkit";

export const registrationFormSlice = createSlice({
  name: "registration",
  initialState: {
    cod_invito: "",
    id_tessera: "",
    codice_sede: "",
    email: "",
    password: "",
    nome: "",
    cognome: "",
    data_nascita: "",
    codice_fiscale: "",
    telefono: "",
    citta: "",
    provincia: "",
    indirizzo: "",
    civico: "",
    tipo_indirizzo: "",
    cap: "",
    privacy: false,
    newsletter: false,
  },

  reducers: {
    setInviteInfo(state, action) {
      state.cod_invito = action.payload.cod_invito;
      state.codice_sede = action.payload.codice_sede;
      state.id_tessera = action.payload.id_tessera;
    },
    setPersonalInfo(state, action) {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.nome = action.payload.nome;
      state.cognome = action.payload.cognome;
      state.data_nascita = action.payload.data_nascita;
      state.codice_fiscale = action.payload.codice_fiscale;
      state.telefono = action.payload.telefono;
      state.citta = action.payload.citta;
      state.provincia = action.payload.provincia;
      state.indirizzo = action.payload.indirizzo;
      state.civico = action.payload.civico;
      state.tipo_indirizzo = action.payload.tipo_indirizzo;
      state.cap = action.payload.cap;
    },
    setSettingInfo(state, action) {
      state.privacy = action.payload.privacy;
      state.newsletter = action.payload.newsletter;
    },
    clearInfo(state) {
      state.cod_invito = "";
      state.id_tessera = "";
      state.codice_sede = "";
      state.email = "";
      state.password = "";
      state.nome = "";
      state.cognome = "";
      state.data_nascita = "";
      state.codice_fiscale = "";
      state.telefono = "";
      state.citta = "";
      state.provincia = "";
      state.indirizzo = "";
      state.civico = "";
      state.tipo_indirizzo = "";
      state.cap = "";
      state.privacy = false;
      state.newsletter = false;
    },
  },
  clearInfo(state) {
    state.cod_invito = "";
    state.id_tessera = "";
    state.codice_sede = "";
    state.email = "";
    state.password = "";
    state.nome = "";
    state.cognome = "";
    state.data_nascita = "";
    state.codice_fiscale = "";
    state.telefono = "";
    state.citta = "";
    state.provincia = "";
    state.indirizzo = "";
    state.civico = "";
    state.tipo_indirizzo = "";
    state.cap = "";
    state.privacy = false;
    state.newsletter = false;
  },
});

export const { setInviteInfo, setPersonalInfo, setSettingInfo, clearInfo } =
  registrationFormSlice.actions;
