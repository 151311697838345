

import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BasicTable from "./BasicTable";
import Table from "react-bootstrap/Table";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function SuoloModal(props) {


    var data = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };



    const [show, setShow] = useState(false);
    const [key, setKey] = useState('suolo');

    const handleClose = () => {
        setShow(false);
        props.setSuoloVisible(false);
    }


    const handleShow = () => {


    }

    // alert(props.Spvisible);
    // if(props.visible) setShow(true);
    //console.log("Da inps Modal");
    //  if(props.suoloData.data) //console.log(props.inpsData.data[0].stat_pubblico_privato);
    //console.log(props.inpsData.data[0].stat_pubblico_privato);
    console.log(props.suoloData);
    if (props.suoloData != null) {
        var data = [
            { name: 'Group A', value: props.suoloData.CSUOLO1 },
            { name: 'Group B', value: props.suoloData.CSUOLO2 },
            { name: 'Group C', value: props.suoloData.CSUOLO3 },

        ];

        var data2 = [
            { name: 'Group A', value: props.suoloData.PIDARU1 },
            { name: 'Group B', value: props.suoloData.PIDRAU4 },
            { name: 'Group C', value: props.suoloData.PIDRAU7 },

        ];
        var data3 = [
            { name: 'Group A', value: props.suoloData.CSISMI1 },
            { name: 'Group B', value: props.suoloData.CSISMI2 },


        ];
        var data4 = [
            { name: 'Group A', value: props.suoloData.PFRANE1 },
            { name: 'Group B', value: props.suoloData.PFRANE2 },  
            { name: 'Group C', value: props.suoloData.PFRANE3 },
            { name: 'Group D', value: props.suoloData.PFRANE4 },



        ];
    }
    return (
        <>


            <Modal show={props.suoloVisible} onHide={handleClose} scrollable className="fixed bottom-1/2 left-1/2  group z-[100000]">
                <Modal.Header closeButton>
                    <Modal.Title> Dati del suolo comune {props.comune} </Modal.Title>

                </Modal.Header>
                <Modal.Body >
                    <div className="h-1/3" scroll>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            <Tab eventKey="suolo" title="Uso del Suolo">

                                <PieChart width={400} height={400}>
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart> <br></br>
                                <div className='flex'><div style={{ backgroundColor: "#0088FE" }} className='bg rounded-full w-8 h-8' >
                                </div> Consumato sulla Superfice Amministrativa </div>
                                <div className='flex'><div style={{ backgroundColor: "#00C49F" }} className='bg rounded-full w-8 h-8' >
                                </div> Non consumato sulla Superfice Amministrativa </div>
                                <div className='flex'><div style={{ backgroundColor: "#FFBB28" }} className='bg rounded-full w-8 h-8' >
                                </div> Non classificato per mancanza di dati </div>




                            </Tab>
                            <Tab eventKey="idrica" title="Pericolosità Idraulica">

                                <PieChart width={400} height={400}>
                                    <Pie
                                        data={data2}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data2.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart> <br></br>
                                <div className='flex'><div style={{ backgroundColor: "#0088FE" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità idraulica bassa </div>
                                <div className='flex'><div style={{ backgroundColor: "#00C49F" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità idriaulica media </div>
                                <div className='flex'><div style={{ backgroundColor: "#FFBB28" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità idriaulica alta </div>




                            </Tab>

                            <Tab eventKey="sismica" title="Pericolosità Sismica">

                                <PieChart width={400} height={400}>
                                    <Pie
                                        data={data3}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data3.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart> <br></br>
                                <div className='flex'><div style={{ backgroundColor: "#0088FE" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità alta </div>
                                <div className='flex'><div style={{ backgroundColor: "#00C49F" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità molto alta </div>





                            </Tab>
                            <Tab eventKey="frane" title="Pericolosità Frane">

                                <PieChart width={400} height={400}>
                                    <Pie
                                        data={data4}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data4.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart> <br></br>
                                <div className='flex'><div style={{ backgroundColor: "#0088FE" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità Moderata </div>
                                <div className='flex'><div style={{ backgroundColor: "#00C49F" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità Media </div>

                                <div className='flex'><div style={{ backgroundColor: "#FFBB28" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità Elevata </div>
                                <div className='flex'><div style={{ backgroundColor: "#FF8042" }} className='bg rounded-full w-8 h-8' >
                                </div> Pericolosità Molto Elevata </div>




                            </Tab>







                        </Tabs>

                    </div>


                    {/* <table class="table" >
                        <thead class=" text-gray-700  bg-green-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-5"> Codice   </th>
                                <th scope="col" class="px-6 py-5"> Descrizione  </th>
                                <th scope="col" class="px-6 py-5"> Tot.  </th>
                            </tr>`
                        </thead>
                        <tbody>          {props.spData.map((dato) => (
                              <tr>
                                <th scope="row">{dato.codice_missione_arconet}</th>
                                <td>{dato.desc_missione_arconet}</td>
                                <td>{dato.tot_pre_cassa_ep}</td>
                                    </tr>
                        ))}

                        </tbody>

                    </table> */}



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>

                </Modal.Footer>
            </Modal >
        </>
    )
}







