


import React from "react";
import "./Style/App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import { store } from "./State/Store";
import { Provider } from "react-redux";

import MainPageTest from "./Pages/MainPageTest";

import KeycloakAuth from "./Componets/KeycloakAuth";
import '@coreui/coreui/dist/css/coreui.min.css';
import Registration from "./Pages/Registration"
import News from "./Pages/News";
import TestDetails from "./Componets/TestDetails";
import Details from "./Componets/Details";
import Events from "./Pages/Events";
import { BrowserRouter as Router } from 'react-router-dom';



export function Paladin() {
  return (
    <>
      <Provider store={store}>
      
        <Routes>
          
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<KeycloakAuth />} />
          <Route path="/main" element={<MainPageTest />} />
          <Route path="/forms" element={<Registration />} />
          <Route path="/news/:token?" element={<News />}/>
          <Route path="/events/:token?" element={<Events/>}/>

          
    

          {/* Forms Pages */}




        </Routes>
       
      </Provider>
    </>
  );
}