import React from "react";
import { useState } from "react";




const SidebarCustom = (props) => {



   //var datiMainLocali=[];

   const [datiMainLocali, setData] = useState();


   const setDataMain = (dati) => {
      setData(dati);
   }

 const setMainPosition = (lat,lan) =>
 {
  props.setGpsState(false);
   props.setPosition([lat,lan]);
   props.setFlyTo([lat,lan]);
   
 
  };

// const handleClick = () => {
//   if (inputRef.current.value !== null) {
//             //  alert("Input is :"+ inputRef.current.value);
//               props.setSearch(inputRef.current.value);
//     console.log(inputRef.current.value);
//   }
// };



   //props.syncToParent();
   


   setInterval(() => {
      //console.log('Interval triggered');
      if (props.DatiMain() != null) {
         setDataMain(props.DatiMain());
         props.settaCaricamento("Hidden");
      }
   }, 500);
   if(datiMainLocali)
   {

    
   return (<>
  <div    class="table-responsive">
<table class="text-sm text-left text-gray-500 dark:text-gray-400"  >

  <thead class="text-xs text-gray-700 uppercase bg-green-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
      <th  scope="col" class="px-6 py-3">  <h6 align="center" ><br></br>Trovati {datiMainLocali.length} risultati </h6>   </th>
    
      
    </tr>`
  </thead>

      <tbody>

         
        

            {
               //            {dato.lat} {dato.lon} {dato.tags.amenity}  {dato.tags.name} {dato.tags.phone} {dato.tags.addr}


               datiMainLocali.map((dato) =>
                  
                  
                   <>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 "   ><th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    
                    {}
                    <div dangerouslySetInnerHTML={{__html: dato.icon.options.html.replace("visibility:visible","visibility:hidden")}} />
                  
                
                    {
                    dato.tags.name==null ? ("Generic "+dato.tags.amenity) : (dato.tags.name+" ("+dato.tags.amenity+")")
                    }
                    

                    <br></br>
                    {dato.tags['addr:street']}
                  
                  {
                    
                    dato.tags['addr:housenumber']===null ? ("") : (dato.tags['addr:housenumber'])
                  }
                 
                  `

                  
                  
                  
                  
                
                    
                    </th>
                  <td ><div className="  ">
                  {  
                    dato.tags['phone']==null ? ("") : ( <a href={`tel:${dato.tags['phone']}`} ><img src="/IMG/phone.svg" width="50"></img></a>)
                  }
                 
                   <img src="/IMG/share.svg" width="50"  onClick={()=>setMainPosition(`${dato.lat}`,`${dato.lon}` )  } ></img>
                 </div> </td></tr>
                  </>
                  
                  )}


      </tbody>
     </table></div></>
   )}else return ( <div></div>)
};
export default SidebarCustom;

