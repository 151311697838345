import { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BasicTable from "./BasicTable";
import Table from "react-bootstrap/Table";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function InpsModal(props) {

    const [show, setShow] = useState(false);
    const [key, setKey] = useState('inps');

    const handleClose = () => {
        setShow(false);
        props.setInpsVisible(false);
    }


    const handleShow = () => {

    }

    // alert(props.Spvisible);
    // if(props.visible) setShow(true);
    //console.log("Da inps Modal");
    if(props.inpsData.data) //console.log(props.inpsData.data[0].stat_pubblico_privato);
    if(props.inpsData.data) //console.log(props.inpsData.data[0].stat_pubblico_privato);
    return (
        <>
            

            <Modal show={props.inpsVisible} onHide={handleClose} scrollable className="fixed bottom-1/2 left-1/2  group z-[100000]">
                <Modal.Header closeButton>
                    <Modal.Title> Statistiche Retribuzione Inps {props.comune} {props.inpsData.data[4].anno}</Modal.Title>

                </Modal.Header>
                <Modal.Body scroll>
                    <div className="h-1/3" scroll>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            <Tab eventKey="inps" title="Dipendenti">
                            <Table striped bordered hover size="sm"  >
                                <thead>
                                    <tr>
                                        
                                        <th>Tipologia</th><th>Reddito Medio (Euro €)</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                
                                     {props.inpsData.data[0].stat_pubblico_privato.map((dato) => (
                                     
                                        <tr>
                                            
                                             <td>{dato.categoria.replace('retribuzione', '')}</td>
                                            <td>{dato.reddito_medio.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>                                            
                                           
                                        </tr>
                                    ))} 
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="inps2" title="Sesso">
                            <Table striped bordered hover size="sm"  >
                                <thead>
                                    <tr>
                                        
                                        <th>Sesso</th><th>Reddito Medio (Euro €)</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                
                                     {props.inpsData.data[1].stat_sesso.map((dato) => (
                                     
                                        <tr>
                                            
                                             <td>{dato.categoria}</td>
                                            <td>{dato.reddito_medio.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>                                            
                                           
                                        </tr>
                                    ))} 
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="inps4" title="Tip.Contrattuale">
                            <Table striped bordered hover size="sm"  >
                                <thead>
                                    <tr>
                                        
                                        <th>Tipologia Contrattuale</th><th>Reddito Medio (Euro €)</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                
                                     {props.inpsData.data[3].stat_tipologia_contrattuale.map((dato) => (
                                     
                                        <tr>
                                            
                                             <td>{dato.categoria}</td>
                                            <td>{dato.reddito_medio.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>                                            
                                           
                                        </tr>
                                    ))} 
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="inps3" title="Qualifica">
                            <Table striped bordered hover size="sm"  >
                                <thead>
                                    <tr>
                                        
                                        <th>Qualifica</th><th>Reddito Medio (Euro €)</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                
                                     {props.inpsData.data[2].stat_qualifica.map((dato) => (
                                     
                                        <tr>
                                            
                                             <td>{dato.categoria}</td>
                                            <td>{dato.reddito_medio.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>                                            
                                           
                                        </tr>
                                    ))} 
                                </tbody>
                            </Table>
                        </Tab>


                        
 
                    </Tabs>
                </div>


                {/* <table class="table" >
                        <thead class=" text-gray-700  bg-green-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-5"> Codice   </th>
                                <th scope="col" class="px-6 py-5"> Descrizione  </th>
                                <th scope="col" class="px-6 py-5"> Tot.  </th>
                            </tr>`
                        </thead>
                        <tbody>          {props.spData.map((dato) => (
                              <tr>
                                <th scope="row">{dato.codice_missione_arconet}</th>
                                <td>{dato.desc_missione_arconet}</td>
                                <td>{dato.tot_pre_cassa_ep}</td>
                                    </tr>
                        ))}

                        </tbody>

                    </table> */}



            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Chiudi
                </Button>

            </Modal.Footer>
        </Modal >
        </>
    )
}
