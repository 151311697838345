import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorState } from "./ErrorState";

export const selectPosition = createSlice({
  name: "position",
  initialState: [],
  reducers: {
    add: (state, action) => {
      state.push(action.payload);
    },
    clear: [],
  },
});

export function fetchPosition(search) {
  return async function (dispatch, getState) {
    try {
      await axios
        .get(
          `https://nominatim.openstreetmap.org/search?q=${search}&countrycodes=it&limit=1&format=jsonv2`
        )
        .then((res) => {
          const position = res.data[0];
          dispatch(selectPosition.actions.add(position));
          //   console.log(position);
        });
    } catch (error) {
      dispatch(errorState.actions.add(error));
    }
  };
}
