import { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BasicTable from "./BasicTable";
import Table from "react-bootstrap/Table";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";

export default function TestDetails({ navigation }) {
    return (
      <div>
        <h2>Home</h2>
        <Link to="/details/123/John">Go to Details</Link>
      </div>
    );
  }