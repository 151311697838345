import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "flowbite-react";
import save from "../IMG/save.svg";
import saved from "../IMG/saved.svg";
import starSolid from "../IMG/starSolid.svg";
import starRegular from "../IMG/starRegular.svg";
import trashRegular from "../IMG/trashRegular.svg";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

function News() {
 
  const { token } = useParams();
  const pageSize = 8;
  const [news, setNews] = useState([]);
  const [categoria, setCategoria] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [favoriteNews, setFavoriteNews] = useState([]);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [calendarIsOpen1, setCalendarIsOpen1] = useState(false);
  const [calendarIsOpen2, setCalendarIsOpen2] = useState(false);
  const [initialDate, setiInitialDate] = useState("");
  const [finalDate, setiFinalDate] = useState("");
  const [showModal, setShow] = useState(false); // Stato per mostrare/nascondere il Modal
  const [modalNews, setModalNews] = useState({});


  console.log("Passaggio parametri da news");
  console.log(token);
  console.log("____________________________________________");


  const paginate = (array, itemsPerPage) => {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / itemsPerPage);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // Inizializza una nuova pagina
      }
      resultArray[chunkIndex].push(item); // Aggiunge l'elemento alla pagina corrente
      return resultArray;
    }, []);
  };

  const handleToggleFavorites = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
    setCurrentPage(0);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("it-IT", options).format(
      date
    );
    return formattedDate;
  }

  useEffect(() => {
  
    const getNews = () => {
      // axios
      //   .get("http://10.20.2.3:30005/api/notizias/?populate=*")
      axios(`https://paladin.expleoitalia.it/service/api/strapi/api/notizias/?populate=*`,   {
        method: 'get', headers: { 'Content-Type': 'text/plain', 'Authorization': "Bearer " + token },
      }, { timeout: 2000 }).then((response) => {
          const newsData = response.data.data.map((item) => {
            return {
              ...item,
              favorite: false,
            };
          });
          setNews(newsData);
          setTotalItems(newsData.length);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getNews();
  }, []);

  useEffect(() => {
    console.log(news);
    const updatedFavoriteNews = news.flat().filter((item) => item.favorite);
    setFavoriteNews(updatedFavoriteNews);
  }, [news]);

  const styleCategorie = {
    color: categoria === "" ? "var(--pld-placeholder)" : "var(--pld-text)",
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.style.transform = "scale(1.05)";
    event.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.3)";
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.transform = "scale(1)";
    event.currentTarget.style.boxShadow = "none";
  };

  const handlePageChange = (selectedPage) => {
    const page = selectedPage.selected;
    setCurrentPage(page);
  };

  const handleFav = (id) => {
    const updatedNews = news.map((item) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          favorite: !item.favorite,
        };
  
        // Aggiorna l'elemento specifico nell'array favoriteNews
        const updatedFavoriteNews = [...favoriteNews]; // Crea una copia dell'array favoriteNews
        const index = updatedFavoriteNews.findIndex(
          (newsItem) => newsItem.id === id
        );
  
        if (index !== -1) {
          updatedFavoriteNews.splice(index, 1); // Rimuovi l'elemento se è già presente
        } else {
          updatedFavoriteNews.push(item); // Aggiungi l'elemento se non è presente
        }
  
        setFavoriteNews(updatedFavoriteNews);
  
        return updatedItem;
      }
      return item;
    });
  
    setNews(updatedNews);
  };
  

  const handleCalendar1 = () => {
    if (!calendarIsOpen1) {
      setCalendarIsOpen1(!calendarIsOpen1);
    } else if (calendarIsOpen1 && !initialDate) {
      setCalendarIsOpen1(!calendarIsOpen1);
    } else {
      return;
    }
  };

  const handleCalendar2 = () => {
    if (!calendarIsOpen2) {
      setCalendarIsOpen2(!calendarIsOpen2);
    } else if (calendarIsOpen2 && !finalDate) {
      setCalendarIsOpen2(!calendarIsOpen2);
    } else {
      return;
    }
  };

  const handleModal = (id) => {
    setShow(!showModal);
    const newsSelected = news.find((item) => item.id === id);
    if (newsSelected) {
      setModalNews(newsSelected);
    }
  };

  const stampa = () => {
    console.log(news);
    console.log(favoriteNews);
  };

  const totalPages = showFavoritesOnly
    ? Math.ceil(favoriteNews.length / pageSize)
    : Math.ceil(totalItems / pageSize);

  const getFavIcon = (item) => {
    if (showFavoritesOnly) {
      return (
        <img
          src={trashRegular}
          alt="rimuovi dai preferiti"
          style={{ width: "15px", height: "20px" }}
          onClick={() => {
            handleFav(item.id);
            if (showModal) {
              handleModal();
            }
          }}
        />
      );
    } else {
      return item.favorite ? (
        <img
          src={saved}
          alt="salvato"
          style={{ width: "15px", height: "20px" }}
        />
      ) : (
        <img src={save} alt="salva" style={{ width: "15px", height: "20px" }} />
      );
    }
  };

  const filteredNews = showFavoritesOnly
    ? paginate(favoriteNews, pageSize)
    : paginate(news, pageSize);


  return (
    <>
      <div className="flex flex-col items-center h-full sticky">
        <div className="w-full text-center bg-white pt-4">
          <h1 className="text-5xl">News</h1>
          <p className="text-sm px-12 sm:text-xl">
            Questa sezione raccoglie tutte le notizie sulle varie tematiche che
            interessano i consumatori
          </p>
        </div>
        <div className="w-full md:w-[60%] flex items-center flex-col">
          <div className="flex flex-col lg:flex-row gap-2 p-2 justify-between w-full">
            {calendarIsOpen1 ? (
              <input
                className="w-100 h-10 pldInput"
                type="date"
                value={initialDate}
                onChange={(e) => setiInitialDate(e.target.value)}
                onBlur={handleCalendar1}
              />
            ) : (
              <input
                className="w-100 h-10 pldInput rounded-md"
                placeholder="Dal"
                onClick={handleCalendar1}
                type="textarea"
              />
            )}

            {calendarIsOpen2 ? (
              <input
                className="w-100 h-10 pldInput"
                type="date"
                value={finalDate}
                onChange={(e) => setiFinalDate(e.target.value)}
                onBlur={handleCalendar2}
              />
            ) : (
              <input
                className="w-100 h-10 pldInput rounded-md"
                placeholder="Al"
                onClick={handleCalendar2}
                type="textarea"
              />
            )}
            <select
              className="pldInput w-100 h-10 rounded-md w-full"
              onChange={(e) => setCategoria(e.target.value)}
              defaultValue=""
              style={styleCategorie}
            >
              {" "}
              <option value={""} key={""} disabled>
                Aree Tematiche
              </option>
              <option value="Ambiente">Ambiente</option>
              <option value="Politica">Politica</option>
              <option value="Cultura">Cultura</option>
            </select>
            <div className="flex flex-row w-full">
              <button
                className="bg-white rounded-lg w-16 h-10"
                onClick={handleToggleFavorites}
              >
                {showFavoritesOnly ? (
                  <img
                    src={starSolid}
                    alt="Mostra tutti"
                    style={{ width: "100px", height: "20px" }}
                  />
                ) : (
                  <img
                    src={starRegular}
                    alt="Mostra i preferiti"
                    style={{ width: "100px", height: "20px" }}
                  />
                )}
              </button>
              <button className="pldButton w-full" onClick={stampa}>
                Cerca
              </button>
            </div>
          </div>
          {filteredNews.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-8 rounded-lg mb-16">
              {filteredNews[currentPage].map((item) => (
                <>
                {modalNews && modalNews.attributes && (
                  <Modal show={showModal} onClose={() => handleModal()}>
                    <Modal.Header>
                      <div className="flex justify-center flex-col text-center pldBold">
                        <img
                          src={
                            "https://paladin.expleoitalia.it/strapi" +
                            modalNews.attributes.Immagine.data.attributes.url.replace('/uploads', '')
                          }
                          alt={
                            modalNews.attributes.Immagine.data.attributes
                              .caption
                          }
                        />
                        {modalNews.attributes.Titolo}
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="space-y-6">
                        <div className="pldBold">
                        {modalNews.attributes.cat_notizies.data[0].attributes.Testo}
                        </div>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                          {modalNews.attributes.Testo}
                        </p>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                  </Modal>
                )}
                <div
                  key={item.id}
                  className="pldCard my-6 mx-2 h-[95%] last:mb-0 pt-4 rounded-lg relative"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ transition: "transform 0.2s, box-shadow 0.2s" }}
                >
                  <div onClick={() => handleModal(item.id)}>
                    <div className="flex justify-center">
                      <img
                        src={
                          "https://paladin.expleoitalia.it/strapi" +
                          item.attributes.Immagine.data.attributes.url.replace('/uploads', '')
                        }
                        alt={item.attributes.Immagine.data.attributes.caption}
                      />
                    </div>
                    <div className="text-xs pldBold flex items-center justify-start py-2 px-2">
                      {formatDate(item.attributes.publishedAt)}
                    </div>
                    <div className="flex flex-row justify-center w-full px-2 mx-2 h-14 relative">
                      <h2 className="pldBold text-start flex items-center pldText text-xl mr-2 text-auto sm:text-base absolute">
                        {item.attributes.Titolo}
                      </h2>{" "}
                    </div>
                    {item.attributes.Titolo.length <= 60 ? (
                      <div className=" mx-2" style={{ overflow: "hidden" }}>
                        <p className="articolo text-xs px-2 mb-12">
                          {item.attributes.Testo}
                        </p>
                      </div>
                    ) : null} </div>
                    <div className="w-full flex flex-row absolute bottom-0 justify-between">
                      <div className="pldBold text-xs flex items-center px-2">
                        {item.attributes.cat_notizies.data[0].attributes.Testo}
                      </div>
                      <div className="bottom-0 w-full flex justify-end">
                        <button
                          className="pr-2 pb-2"
                          onClick={() => handleFav(item.id)}
                        >
                          {getFavIcon(item)}
                        </button>
                      </div>
                    </div>
                 
                </div></>
              ))}
            </div>
          ) : (
            <p className="text-center">Nessuna notizia trovata!</p>
          )}
        </div>
      </div>
      <div className="bottom-0 fixed flex items-center w-full">
        {totalPages !== 1 ? (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            className="bg-white rounded-0 flex flex-row gap-2 mb-0 text-center w-full justify-center"
            style={{ padding: "1rem", placeContent: "center" }}
          />
        ) : null}
      </div>
    </>
  );
}

export default News;
